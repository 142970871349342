export type Maybe<T> = T | null

export interface InputBoardTemplateFeaturesIndex {
  readonly allergens?: Maybe<boolean>

  readonly availableColors?: Maybe<number>

  readonly infoMessage?: Maybe<boolean>

  readonly logo?: Maybe<boolean>

  readonly screenTitle?: Maybe<boolean>

  readonly promo?: Maybe<boolean>
}

export interface InputStatisticCreate {
  readonly dateFrom?: Maybe<Date>

  readonly dateTo?: Maybe<Date>

  readonly dateTimeFrom?: Maybe<Date>

  readonly dateTimeTo?: Maybe<Date>

  readonly branches?: Maybe<ReadonlyArray<string>>

  readonly drivers?: Maybe<ReadonlyArray<string>>

  readonly cashBoxes?: Maybe<ReadonlyArray<string>>

  readonly cashiers?: Maybe<ReadonlyArray<string>>

  readonly sectors?: Maybe<ReadonlyArray<string>>

  readonly sectorsWithChildren?: boolean

  readonly orderOrigins?: Maybe<ReadonlyArray<OrderOriginEnum>>

  readonly occasions?: Maybe<ReadonlyArray<string>>

  readonly country?: Maybe<CountryEnum>

  readonly customerPhone?: Maybe<string>

  readonly paymentGates?: Maybe<ReadonlyArray<PaymentGateEnum>>
}

export interface InputOrderIndex {
  readonly companyBranches?: Maybe<ReadonlyArray<string>>

  readonly user?: Maybe<string>

  readonly orderStatesAND?: Maybe<ReadonlyArray<OrderStateEnum>>

  readonly orderStatesOR?: Maybe<ReadonlyArray<OrderStateEnum>>

  readonly orderStateCategoriesOR?: Maybe<ReadonlyArray<OrderStateCategoryEnum>>

  readonly orderStateCategoryTarget?: OrderStateCategoryTargetEnum

  readonly currency?: Maybe<CurrencyEnum>

  readonly location?: Maybe<InputLocationIndex>
  /** Return only records changed after argument value. */
  readonly stateChangeSince?: Maybe<Date>

  readonly includeOldFinished?: boolean

  readonly sectors?: Maybe<ReadonlyArray<string>>

  readonly sectorsWithChildren?: boolean

  readonly occasions?: Maybe<ReadonlyArray<string>>

  readonly groups?: Maybe<ReadonlyArray<string>>
}

export interface InputLocationIndex {
  readonly tableNumber?: Maybe<number>
}

export interface InputPaginationParams {
  readonly fromIndex: number

  readonly toIndex: number
}

export interface InputPaymentMethodIndex {
  readonly companyBranch: string

  readonly orderOrigin: OrderOriginEnum

  readonly warePriceType: WarePriceTypeEnum

  readonly paymentType: PaymentTypeEnum

  readonly paymentGate: PaymentGateEnum

  readonly enabled?: boolean
}

export interface InputPromoCodeIndex {
  readonly companyBranch?: Maybe<string>

  readonly brand?: Maybe<string>

  readonly enabled?: Maybe<boolean>
}

export interface InputFeeStatistics {
  readonly branches: ReadonlyArray<string>

  readonly months: ReadonlyArray<MonthAndYear>

  readonly country?: Maybe<CountryEnum>

  readonly orderOrigins?: Maybe<ReadonlyArray<OrderOriginEnum>>

  readonly operatingCostTypes?: Maybe<ReadonlyArray<OperatingCostTypeEnum>>
}
/** Representation of month in the year */
export interface MonthAndYear {
  readonly month?: Maybe<number>

  readonly year?: Maybe<number>
}

export interface InputVamStatus {
  readonly email?: Maybe<string>

  readonly phone?: Maybe<string>

  readonly firstname?: Maybe<string>

  readonly lastname?: Maybe<string>

  readonly orderId: string
}

export interface InputBusinessHourSpecificDateCreate {
  readonly companyBranch: string

  readonly openAt?: Maybe<string>

  readonly closeAt?: Maybe<string>

  readonly specificDate: Date

  readonly closed?: boolean

  readonly smsLabel?: Maybe<InputLabelCreate>

  readonly webLabel?: Maybe<InputLabelCreate>

  readonly closedNote?: Maybe<string>

  readonly type?: BusinessHoursTypeEnum
}

export interface InputLabelCreate {
  readonly translations: ReadonlyArray<InputLabelTranslation>

  readonly enabled?: boolean
}

export interface InputLabelTranslation {
  readonly language: LanguageEnum

  readonly content: string
}

export interface InputBusinessHourSpecificCloseCreate {
  readonly companyBranchId: string
  /** close for defined interval in minutes */
  readonly closingInterval: number

  readonly closedNote?: Maybe<string>
}

export interface InputBoardFeaturesCreate {
  readonly allergensEnabled: boolean

  readonly titleEnabled: boolean

  readonly titleText?: Maybe<string>

  readonly titleUrl?: Maybe<string>

  readonly promoEnabled: boolean

  readonly promoCategory?: Maybe<string>

  readonly logoEnabled: boolean

  readonly infoMessageEnabled: boolean

  readonly infoMessagePrimary?: Maybe<string>

  readonly infoMessageSecondary?: Maybe<string>
}

export interface InputBusinessHourCreate {
  readonly weekDay: WeekDayEnum

  readonly openAt: string

  readonly closeAt: string

  readonly smsLabel?: Maybe<InputLabelCreate>

  readonly webLabel?: Maybe<InputLabelCreate>
}

export interface InputCompanyCreate {
  readonly name: string

  readonly number: string

  readonly vatIdent: string

  readonly account: string

  readonly internalNumber?: Maybe<string>

  readonly companyType: CompanyTypeEnum
}

export interface InputCompanyBranchCreate {
  readonly currencies?: ReadonlyArray<CurrencyEnum>

  readonly name: string

  readonly enabled?: boolean

  readonly brand: string

  readonly internalNumber: string
}

export interface InputCompanyBranchUserDriverSetting {
  readonly orderAssignmentTypes?: Maybe<
    ReadonlyArray<UserDeliveringOrdersAssignmentTypeEnum>
  >

  readonly driverSmsText?: Maybe<string>
}

export interface InputOrderDeliveryCreate {
  readonly companyBranch?: Maybe<string>

  readonly brand?: Maybe<string>

  readonly orderOrigin: OrderOriginEnum

  readonly gps: InputGeoPointCreate

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly address?: Maybe<string>

  readonly deliverySlot?: Maybe<string>
}

export interface InputGeoPointCreate {
  readonly latitude: number

  readonly longitude: number
}

export interface InputExternalOrderCreate {
  readonly companyBranch: string

  readonly orderOrigin: OrderOriginEnum

  readonly deliverAt: Date

  readonly gps?: Maybe<InputGeoPointCreate>

  readonly address?: Maybe<string>

  readonly warePriceType?: WarePriceTypeEnum

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly orderRecipes?: Maybe<ReadonlyArray<InputOrderRecipeCreates>>

  readonly customer?: Maybe<InputCustomerCreate>

  readonly note?: Maybe<string>

  readonly cancelUnpaid?: boolean
}

export interface InputOrderRecipeCreates {
  readonly recipe: string

  readonly sideDishes?: Maybe<ReadonlyArray<string>>

  readonly associatedRecipes?: Maybe<ReadonlyArray<string>>

  readonly amount?: number

  readonly note?: Maybe<string>
}

export interface InputCustomerCreate {
  readonly firstName?: Maybe<string>

  readonly lastName?: Maybe<string>

  readonly emails?: Maybe<ReadonlyArray<InputEmailCreate>>

  readonly phones?: Maybe<ReadonlyArray<InputPhoneCreate>>
}

export interface InputEmailCreate {
  readonly email: string

  readonly description?: Maybe<string>

  readonly default?: boolean
}

export interface InputPhoneCreate {
  readonly phone: string

  readonly description?: Maybe<string>

  readonly default?: boolean
}

export interface InputIngredientCreate {
  readonly recipe: string

  readonly stockItem: string

  readonly quantity: number
}

export interface InputOccasionCreate {
  readonly companyBranch: string

  readonly title: string
  /** Short title for mobile view */
  readonly titleShort: string
  /** Date when occasion begins */
  readonly dateFrom: Date
  /** Date when occasions ends */
  readonly dateTo: Date
  /** Beginning of preorder */
  readonly preorderFrom: Date
  /** End of preorder */
  readonly preorderTo: Date

  readonly image?: Maybe<InputFile>
}

export interface InputOrderCreate {
  readonly companyBranch: string

  readonly orderOrigin: OrderOriginEnum

  readonly warePriceType: WarePriceTypeEnum

  readonly deliveryType?: DeliveryTypeEnum

  readonly language?: Maybe<LanguageEnum>

  readonly customer: InputCustomerCreate
  /** Optional for INHOUSE-PICKUP order and for MESSENGER delivering orders associateg with Sector, which has defines its GPS. */
  readonly gps?: Maybe<InputGeoPointCreate>
  /** Argument is not reguired for STAFF orders. Also we try to reverse geocode GPS to address, thus argument may be blank. */
  readonly address?: Maybe<string>

  readonly notification?: Maybe<InputNotificationCreate>

  readonly note?: Maybe<string>

  readonly deliverAt?: Maybe<Date>

  readonly location?: Maybe<InputLocationCreate>

  readonly callEventId?: Maybe<string>

  readonly sector?: Maybe<string>

  readonly occasion?: Maybe<string>

  readonly deliverySlot?: Maybe<string>
}

export interface InputNotificationCreate {
  readonly sms?: Maybe<string>

  readonly chrome?: Maybe<{ [key: string]: string | number }>

  readonly firebase?: Maybe<{ [key: string]: string | number }>
}

export interface InputLocationCreate {
  readonly tableNumber?: Maybe<number>
}

export interface InputOrderGroupCreate {
  readonly name?: Maybe<string>

  readonly orderGroupColor?: Maybe<string>

  readonly orders: ReadonlyArray<string>
}

export interface InputOrderPaymentMethodCreate {
  readonly order: string

  readonly paymentMethod: string

  readonly price: InputCurrencyCreate
}

export interface InputCurrencyCreate {
  readonly value: number

  readonly currency?: Maybe<CurrencyEnum>
}

export interface InputOrderRatingCreate {
  readonly orderRatingType: OrderRatingTypeEnum

  readonly text?: Maybe<string>

  readonly value?: Maybe<number>
}

export interface InputOrderRecipeCreate {
  readonly order: string

  readonly recipe: string

  readonly orderRecipeState?: Maybe<OrderRecipeStateEnum>

  readonly parent?: Maybe<string>

  readonly sideDishes?: Maybe<ReadonlyArray<string>>

  readonly associatedRecipes?: Maybe<ReadonlyArray<string>>

  readonly amount?: number

  readonly note?: Maybe<string>

  readonly wareCategory?: Maybe<string>

  readonly promoTag?: Maybe<PromoTagEnum>
}

export interface InputOrderPickupCreate {
  readonly companyBranch: string

  readonly orderOrigin: OrderOriginEnum

  readonly deliverySlot?: Maybe<string>
}

export interface InputRecipeCreate {
  readonly companyBranch: string

  readonly nameLabel?: Maybe<string>

  readonly descriptionLabel?: Maybe<string>

  readonly translations?: Maybe<ReadonlyArray<InputRecipeTranslation>>

  readonly internalNote?: Maybe<string>

  readonly wareCategoryType: WareCategoryTypeEnum

  readonly enabled?: boolean

  readonly soldOut?: boolean

  readonly ingredients?: Maybe<ReadonlyArray<InputRecipeIngredient>>

  readonly wareCategories?: Maybe<ReadonlyArray<string>>

  readonly primaryWareCategory?: Maybe<string>

  readonly sideDishCategories?: Maybe<ReadonlyArray<string>>

  readonly availabilities?: Maybe<ReadonlyArray<InputAvailabilityCreate>>

  readonly video?: Maybe<string>

  readonly code?: Maybe<string>

  readonly ware?: Maybe<string>

  readonly prices: ReadonlyArray<InputRecipePriceCreate>

  readonly associatedRecipes?: Maybe<ReadonlyArray<InputRecipeRelationCreate>>

  readonly image?: Maybe<InputFile>

  readonly groups: ReadonlyArray<string>

  readonly dotykackaId?: Maybe<string>

  readonly bistroId?: Maybe<string>

  readonly pictogram?: Maybe<string>

  readonly recipeTags?: Maybe<ReadonlyArray<string>>
}

export interface InputRecipeTranslation {
  readonly language: LanguageEnum

  readonly nameLabel?: Maybe<string>

  readonly descriptionLabel?: Maybe<string>
}

export interface InputRecipeIngredient {
  readonly stockItem: string

  readonly quantity: number
}

export interface InputAvailabilityCreate {
  readonly availabilityType?: AvailabilityTypeEnum

  readonly dateFrom?: Maybe<Date>

  readonly timeFrom?: Maybe<string>

  readonly dateTo?: Maybe<Date>

  readonly timeTo?: Maybe<string>

  readonly available?: boolean

  readonly periodic?: boolean

  readonly weekDay?: Maybe<WeekDayEnum>

  readonly orderOriginCategories?: Maybe<ReadonlyArray<OrderOriginCategoryEnum>>
}

export interface InputRecipePriceCreate {
  readonly warePriceType: WarePriceTypeEnum

  readonly price: InputCurrencyCreate

  readonly vat: string
}

export interface InputRecipeRelationCreate {
  readonly associatedRecipe: string

  readonly warePriceType: WarePriceTypeEnum
}

export interface InputRecipeTagCreate {
  readonly company: string

  readonly systemRecipeTag: string

  readonly translations?: Maybe<ReadonlyArray<InputRecipeTagTranslation>>

  readonly image?: Maybe<InputFile>

  readonly pictogram?: Maybe<string>

  readonly color?: Maybe<string>
}

export interface InputRecipeTagTranslation {
  readonly language: LanguageEnum

  readonly label?: Maybe<string>
}

export interface InputSectorCreate {
  readonly companyBranch: string

  readonly sectorType: SectorTypeEnum

  readonly nameLabel: string

  readonly code: string

  readonly enabled?: boolean

  readonly gps?: Maybe<InputGeoPointCreate>

  readonly warePriceType?: Maybe<WarePriceTypeEnum>

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly parent?: Maybe<string>

  readonly enabledWareCategories?: Maybe<ReadonlyArray<string>>

  readonly disabledWareCategories?: Maybe<ReadonlyArray<string>>

  readonly availabilities?: Maybe<ReadonlyArray<InputAvailabilityCreate>>

  readonly eetEnabled?: Maybe<boolean>

  readonly orderDelaySeconds?: Maybe<number>
}

export interface InputSectorRecipeCreate {
  readonly recipe: string

  readonly sector: string

  readonly parent?: Maybe<string>

  readonly sideDishes?: Maybe<ReadonlyArray<string>>

  readonly associatedRecipes?: Maybe<ReadonlyArray<string>>

  readonly amount?: number

  readonly warePriceType?: WarePriceTypeEnum

  readonly note?: Maybe<string>
}

export interface InputShiftCreate {
  readonly companyBranch: string

  readonly beginAt: Date

  readonly endAt: Date

  readonly capacity?: number

  readonly placeCode: string
}

export interface InputStockItemCreate {
  readonly companyBranch: string

  readonly wareUnit: WareUnitEnum

  readonly label: string

  readonly code: string

  readonly allowNegative: FieldStateEnum

  readonly plu?: Maybe<string>

  readonly quantity?: number

  readonly price?: number

  readonly allergens?: Maybe<ReadonlyArray<AllergenEnum>>

  readonly note?: Maybe<string>
}

export interface InputStockTransferCreate {
  readonly stockItemId: string

  readonly quantity: number
}

export interface InputTransportationDeviceCreate {
  readonly companyBranch: string

  readonly deviceType: string

  readonly deviceState: string

  readonly idNumber: string
}

export interface InputUserCreate {
  readonly firstName: string

  readonly lastName: string

  readonly login: string

  readonly email: string

  readonly phone: string

  readonly roles: ReadonlyArray<RoleEnum>

  readonly companyBranches: ReadonlyArray<string>

  readonly language: LanguageEnum
}

export interface InputWareCreate {
  readonly companyBranch: string

  readonly nameLabel: InputLabelCreate

  readonly descriptionLabel: InputLabelCreate

  readonly code?: Maybe<string>

  readonly internalCode?: Maybe<string>

  readonly enabled?: boolean

  readonly availabilities?: ReadonlyArray<InputAvailabilityCreate>
}

export interface InputWareCategoryCreate {
  readonly companyBranch: string

  readonly sharedCompanyBranches?: Maybe<ReadonlyArray<string>>

  readonly wareCategoryType: WareCategoryTypeEnum

  readonly pictogram?: Maybe<string>

  readonly descriptionLabel?: Maybe<string>

  readonly nameLabel?: Maybe<string>

  readonly internalNoteLabel?: Maybe<string>

  readonly translations?: Maybe<ReadonlyArray<InputWareCategoryTranslation>>

  readonly enabled?: boolean

  readonly public?: boolean

  readonly availabilities?: ReadonlyArray<InputAvailabilityCreate>

  readonly maxRecipesCount?: number

  readonly minCount?: number

  readonly maxCount?: number

  readonly recipes?: Maybe<ReadonlyArray<string>>

  readonly orderOriginCategories?: Maybe<ReadonlyArray<OrderOriginCategoryEnum>>

  readonly wareCategoryColor?: Maybe<string>

  readonly dotykackaId?: Maybe<string>

  readonly promoTags?: Maybe<ReadonlyArray<PromoTagEnum>>

  readonly recipeTags?: Maybe<ReadonlyArray<string>>

  readonly image?: Maybe<InputFile>
}

export interface InputWareCategoryTranslation {
  readonly language: LanguageEnum

  readonly nameLabel?: Maybe<string>

  readonly descriptionLabel?: Maybe<string>

  readonly internalNoteLabel?: Maybe<string>
}

export interface InputOrderWifiCreate {
  readonly companyBranch: string

  readonly orderOrigin: OrderOriginEnum

  readonly warePriceType: WarePriceTypeEnum

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly sector?: Maybe<string>

  readonly occasion?: Maybe<string>
}

export interface InputZoneCreate {
  readonly companyBranch: string

  readonly name?: Maybe<string>

  readonly zoneType?: Maybe<ZoneTypeEnum>

  readonly zoneColor?: Maybe<string>

  readonly enabled?: boolean

  readonly geoPolygon?: Maybe<ReadonlyArray<InputGeoPointCreate>>

  readonly availabilities?: Maybe<ReadonlyArray<InputAvailabilityCreate>>

  readonly priceRangeRules?: Maybe<ReadonlyArray<PriceRangeRuleCreate>>

  readonly minimalOrderPrice?: Maybe<InputCurrencyCreate>
}

export interface PriceRangeRuleCreate {
  readonly price: InputCurrencyCreate

  readonly toPrice: InputCurrencyCreate
}

export interface InputOrderPaymentMethodsCreate {
  readonly paymentType: PaymentTypeEnum

  readonly paymentGate: PaymentGateEnum

  readonly price: InputCurrencyCreate
}

export interface InputOrderFinish {
  readonly customer?: Maybe<InputCustomerCreate>
  /** payment type used to finalize order */
  readonly paymentMethod?: Maybe<PaymentTypeEnum>
  /** payment gate used to finalize order */
  readonly paymentGate?: Maybe<PaymentGateEnum>
  /** id of payment method  used to finalize order */
  readonly paymentMethodId?: Maybe<string>
  /** Preferred time of order delivery. In case that argument is null, deliver_at value is computed based on branch opening hours and default delivery times */
  readonly deliverAt?: Maybe<Date>

  readonly note?: Maybe<string>

  readonly confirmedAgreements?: Maybe<ReadonlyArray<string>>
  /** percentage value of tips */
  readonly tips?: Maybe<number>

  readonly returnUrl?: Maybe<string>

  readonly cancelUrl?: Maybe<string>

  readonly deliverySlot?: Maybe<string>
}

export interface InputOrderStaffImport {
  readonly id?: Maybe<string>

  readonly warePriceType: WarePriceTypeEnum

  readonly companyBranch: string

  readonly acceptedAt?: Maybe<Date>

  readonly acceptedAtRaw?: Maybe<string>

  readonly orderRecipes: ReadonlyArray<InputOrderRecipeCreates>

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly invoice: string

  readonly invoiceNumber: number

  readonly paymentMethods: ReadonlyArray<InputOrderPaymentMethodsCreate>

  readonly canceled?: Maybe<boolean>

  readonly sector?: Maybe<string>

  readonly recipePrices?: Maybe<ReadonlyArray<InputRecipePriceCashBox>>

  readonly totalDiscount?: Maybe<InputCurrencyCreate>

  readonly forceFinish?: boolean

  readonly fik?: Maybe<string>
}

export interface InputRecipePriceCashBox {
  readonly recipe: string

  readonly price: InputCurrencyCreate
}

export interface InputBoardFeaturesUpdate {
  readonly allergensEnabled?: Maybe<boolean>

  readonly titleEnabled?: Maybe<boolean>

  readonly promoEnabled?: Maybe<boolean>

  readonly logoEnabled?: Maybe<boolean>

  readonly infoMessageEnabled?: Maybe<boolean>
}

export interface InputFileUpdate {
  readonly file?: Maybe<InputFile>

  readonly delete?: boolean
}

export interface InputCompanyBranchRecipeUpdate {
  readonly id: string

  readonly enabled?: Maybe<boolean>

  readonly soldOut?: Maybe<boolean>

  readonly dotykackaId?: Maybe<string>

  readonly bistroId?: Maybe<string>
}

export interface InputCompanyBranchUserUpdate {
  readonly deleted?: Maybe<boolean>

  readonly driverSetting?: Maybe<InputCompanyBranchUserDriverSetting>
}

export interface InputCompanyBranchWareCategoryUpdate {
  readonly id: string

  readonly enabled?: Maybe<boolean>

  readonly dotykackaId?: Maybe<string>
}

export interface InputOccasionUpdate {
  readonly title?: Maybe<string>
  /** Short title for mobile view */
  readonly titleShort?: Maybe<string>
  /** Date when occasion begins */
  readonly dateFrom?: Maybe<Date>
  /** Date when occasions ends */
  readonly dateTo?: Maybe<Date>
  /** Beginning of preorder */
  readonly preorderFrom?: Maybe<Date>
  /** End of preorder */
  readonly preorderTo?: Maybe<Date>

  readonly image?: Maybe<InputRecipeImage>
}

export interface InputRecipeImage {
  readonly file?: Maybe<InputFile>

  readonly delete: boolean
}

export interface InputOrderUpdate {
  readonly companyBranch?: Maybe<string>

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly language?: Maybe<LanguageEnum>

  readonly customer?: Maybe<InputCustomerCreate>

  readonly gps?: Maybe<InputGeoPointCreate>

  readonly address?: Maybe<string>

  readonly notification?: Maybe<InputNotificationCreate>

  readonly note?: Maybe<string>

  readonly deliverAt?: Maybe<Date>

  readonly location?: Maybe<InputLocationCreate>

  readonly warePriceType?: Maybe<WarePriceTypeEnum>
  /** percentage value of tips */
  readonly tips?: Maybe<number>

  readonly orderGroup?: Maybe<string>

  readonly deliverySlot?: Maybe<string>
}

export interface InputOrderGroupUpdate {
  readonly id: string

  readonly name?: Maybe<string>

  readonly orderGroupColor?: Maybe<string>

  readonly addedOrders?: Maybe<ReadonlyArray<string>>

  readonly removedOrders?: Maybe<ReadonlyArray<string>>
}

export interface InputOrderPaymentMethodUpdate {
  readonly paymentMethodId: string

  readonly price: InputCurrencyCreate
}

export interface InputOrderRecipeUpdate {
  readonly order?: Maybe<string>

  readonly recipe?: Maybe<string>

  readonly orderRecipeState?: Maybe<OrderRecipeStateEnum>

  readonly parent?: Maybe<string>

  readonly sideDishes?: Maybe<ReadonlyArray<string>>

  readonly note?: Maybe<string>
}

export interface InputRecipeUpdate {
  readonly ware?: Maybe<string>

  readonly nameLabel?: Maybe<string>

  readonly descriptionLabel?: Maybe<string>

  readonly translations?: Maybe<ReadonlyArray<InputRecipeTranslation>>

  readonly enabled?: Maybe<boolean>

  readonly soldOut?: Maybe<boolean>

  readonly internalNote?: Maybe<string>

  readonly video?: Maybe<string>

  readonly code?: Maybe<string>

  readonly ingredients?: Maybe<ReadonlyArray<InputRecipeIngredient>>

  readonly wareCategories?: Maybe<ReadonlyArray<string>>

  readonly primaryWareCategory?: Maybe<string>

  readonly sideDishCategories?: Maybe<ReadonlyArray<string>>

  readonly prices?: Maybe<ReadonlyArray<InputRecipePriceCreate>>

  readonly associatedRecipes?: Maybe<ReadonlyArray<InputRecipeRelationCreate>>

  readonly image?: Maybe<InputRecipeImage>

  readonly groups?: Maybe<ReadonlyArray<string>>

  readonly pictogram?: Maybe<string>

  readonly recipeTags?: Maybe<ReadonlyArray<string>>
}

export interface InputRecipeTagUpdate {
  readonly company?: Maybe<string>

  readonly systemRecipeTag?: Maybe<string>

  readonly translations?: Maybe<ReadonlyArray<InputRecipeTagTranslation>>

  readonly deleteLabel?: Maybe<boolean>

  readonly image?: Maybe<InputRecipeTagImage>

  readonly pictogram?: Maybe<string>

  readonly color?: Maybe<string>
}

export interface InputRecipeTagImage {
  readonly file?: Maybe<InputFile>

  readonly delete?: boolean
}

export interface InputSectorUpdate {
  readonly id: string

  readonly companyBranch?: Maybe<string>

  readonly sectorType?: Maybe<SectorTypeEnum>

  readonly gps?: Maybe<InputGeoPointCreate>

  readonly translations?: Maybe<ReadonlyArray<InputSectorTranslation>>

  readonly code?: Maybe<string>

  readonly enabled?: Maybe<boolean>

  readonly warePriceType?: Maybe<WarePriceTypeEnum>

  readonly deliveryType?: Maybe<DeliveryTypeEnum>

  readonly parent?: Maybe<string>

  readonly enabledWareCategories?: Maybe<ReadonlyArray<string>>

  readonly disabledWareCategories?: Maybe<ReadonlyArray<string>>

  readonly availabilities?: Maybe<ReadonlyArray<InputAvailabilityCreate>>

  readonly eetEnabled?: Maybe<boolean>

  readonly orderDelaySeconds?: Maybe<number>
}

export interface InputSectorTranslation {
  readonly language: LanguageEnum

  readonly nameLabel?: Maybe<string>
}

export interface InputStockInventoryItemUpdate {
  readonly finalQuantity: number

  readonly stockItemChangeType?: Maybe<StockItemChangeTypeEnum>
}

export interface InputStockItemUpdate {
  readonly quantity?: Maybe<number>

  readonly price?: Maybe<number>

  readonly label?: Maybe<string>

  readonly code?: Maybe<string>

  readonly plu?: Maybe<string>

  readonly wareUnit?: Maybe<WareUnitEnum>

  readonly allergens?: Maybe<ReadonlyArray<AllergenEnum>>

  readonly note?: Maybe<string>

  readonly allowNegative?: Maybe<FieldStateEnum>
}

export interface InputUserUpdate {
  readonly firstName?: Maybe<string>

  readonly lastName?: Maybe<string>

  readonly email?: Maybe<string>

  readonly phone?: Maybe<string>

  readonly roles?: Maybe<ReadonlyArray<RoleEnum>>

  readonly language?: Maybe<LanguageEnum>
}

export interface InputWareUpdate {
  readonly code?: Maybe<string>

  readonly internalCode?: Maybe<string>

  readonly companyBranch?: Maybe<string>

  readonly nameLabel?: Maybe<InputLabelUpdate>

  readonly descriptionLabel?: Maybe<InputLabelUpdate>

  readonly enabled?: Maybe<boolean>
}

export interface InputLabelUpdate {
  readonly translations?: Maybe<ReadonlyArray<InputLabelTranslation>>

  readonly enabled?: Maybe<boolean>
}

export interface InputWareCategoryUpdate {
  readonly sharedCompanyBranches?: Maybe<ReadonlyArray<string>>

  readonly pictogram?: Maybe<string>

  readonly nameLabel?: Maybe<string>

  readonly internalNoteLabel?: Maybe<string>

  readonly translations?: Maybe<ReadonlyArray<InputWareCategoryTranslation>>

  readonly wareRequirement?: Maybe<WareRequirementEnum>

  readonly wareCategoryType?: Maybe<WareCategoryTypeEnum>

  readonly enabled?: Maybe<boolean>

  readonly public?: Maybe<boolean>

  readonly maxRecipesCount?: Maybe<number>

  readonly minCount?: Maybe<number>

  readonly maxCount?: Maybe<number>

  readonly recipes?: Maybe<ReadonlyArray<string>>

  readonly orderOriginCategories?: Maybe<ReadonlyArray<OrderOriginCategoryEnum>>

  readonly wareCategoryColor?: Maybe<string>

  readonly promoTags?: Maybe<ReadonlyArray<PromoTagEnum>>

  readonly recipeTags?: Maybe<ReadonlyArray<string>>

  readonly image?: Maybe<InputWareCategoryImage>
}

export interface InputWareCategoryImage {
  readonly file?: Maybe<InputFile>

  readonly delete: boolean
}

export interface InputZoneUpdate {
  readonly companyBranch?: Maybe<string>

  readonly name?: Maybe<string>

  readonly zoneColor?: Maybe<string>

  readonly enabled?: Maybe<boolean>

  readonly geoPolygon?: Maybe<ReadonlyArray<InputGeoPointCreate>>

  readonly availabilities?: Maybe<ReadonlyArray<InputAvailabilityUpdate>>

  readonly priceRangeRules?: Maybe<ReadonlyArray<PriceRangeRuleCreate>>

  readonly minimalOrderPrice?: Maybe<InputCurrencyCreate>

  readonly deliveryTimeDeviation?: Maybe<number>
}

export interface InputAvailabilityUpdate {
  readonly availabilityType?: Maybe<AvailabilityTypeEnum>

  readonly dateFrom?: Maybe<Date>

  readonly timeFrom?: Maybe<string>

  readonly dateTo?: Maybe<Date>

  readonly timeTo?: Maybe<string>

  readonly available?: Maybe<boolean>

  readonly periodic?: Maybe<boolean>

  readonly weekDay?: Maybe<WeekDayEnum>

  readonly orderOriginCategories?: Maybe<ReadonlyArray<OrderOriginCategoryEnum>>
}
/** AddressType enum */
export enum AddressTypeEnum {
  Business = 'BUSINESS',
  Premises = 'PREMISES',
  MainAddress = 'MAIN_ADDRESS',
}
/** Allergen enum */
export enum AllergenEnum {
  CerealsWithGlutten = 'CEREALS_WITH_GLUTTEN',
  Egg = 'EGG',
  Shellfish = 'SHELLFISH',
  Fish = 'FISH',
  Peanut = 'PEANUT',
  Soybeans = 'SOYBEANS',
  Milk = 'MILK',
  Nut = 'NUT',
  Celery = 'CELERY',
  Mustard = 'MUSTARD',
  Sesame = 'SESAME',
  Sulfurous = 'SULFUROUS',
  Lupin = 'LUPIN',
  Molluscs = 'MOLLUSCS',
}
/** OrderOriginCategory enum */
export enum OrderOriginCategoryEnum {
  Doveze = 'DOVEZE',
  Wifi = 'WIFI',
  Staff = 'STAFF',
  Website = 'WEBSITE',
  StaffPos = 'STAFF_POS',
  Menuboard = 'MENUBOARD',
  Kiosk = 'KIOSK',
}
/** AvailabilityType enum */
export enum AvailabilityTypeEnum {
  Main = 'MAIN',
  Specific = 'SPECIFIC',
}
/** WeekDay enum */
export enum WeekDayEnum {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}
/** BusinessHoursType enum */
export enum BusinessHoursTypeEnum {
  Regular = 'REGULAR',
  PlannedChange = 'PLANNED_CHANGE',
  FastClose = 'FAST_CLOSE',
}
/** OrderStateCategoryTarget enum */
export enum OrderStateCategoryTargetEnum {
  Backend = 'BACKEND',
  FeAdmin = 'FE_ADMIN',
  FeKitchen = 'FE_KITCHEN',
  Driver = 'DRIVER',
  FeCompletion = 'FE_COMPLETION',
}
/** OrderStateCategory enum */
export enum OrderStateCategoryEnum {
  Main = 'MAIN',
  NewOrders = 'NEW_ORDERS',
  InCart = 'IN_CART',
  Uncategorized = 'UNCATEGORIZED',
  Preparing = 'PREPARING',
  Production = 'PRODUCTION',
  Payment = 'PAYMENT',
  ReadyToDeliver = 'READY_TO_DELIVER',
  Notification = 'NOTIFICATION',
  OnWay = 'ON_WAY',
  Finished = 'FINISHED',
  Delivery = 'DELIVERY',
  Cancel = 'CANCEL',
  Reclamation = 'RECLAMATION',
  Bills = 'BILLS',
}
/** UserDeliveringOrdersAssignmentType enum */
export enum UserDeliveringOrdersAssignmentTypeEnum {
  Staff = 'STAFF',
  Driver = 'DRIVER',
  CheckedByDriver = 'CHECKED_BY_DRIVER',
}
/** Possible mobile platforms */
export enum MobilePlatformEnum {
  Android = 'ANDROID',
  Ios = 'IOS',
  Pwa = 'PWA',
}
/** Language enum */
export enum LanguageEnum {
  Cs = 'CS',
  Sk = 'SK',
  Pl = 'PL',
  De = 'DE',
  En = 'EN',
  Fr = 'FR',
  Es = 'ES',
  Nl = 'NL',
  Af = 'AF',
  Zu = 'ZU',
  Ru = 'RU',
  It = 'IT',
  Pt = 'PT',
}
/** DeliveryType enum */
export enum DeliveryTypeEnum {
  Pickup = 'PICKUP',
  Messenger = 'MESSENGER',
  Dron = 'DRON',
  Mail = 'MAIL',
}
/** WareUnit enum */
export enum WareUnitEnum {
  Kilogram = 'KILOGRAM',
  Gram = 'GRAM',
  Litre = 'LITRE',
  Piece = 'PIECE',
  Milligram = 'MILLIGRAM',
  Millilitre = 'MILLILITRE',
}
/** Currency enum */
export enum CurrencyEnum {
  Czk = 'CZK',
  Hop = 'HOP',
  Eur = 'EUR',
  Usd = 'USD',
  Pln = 'PLN',
  Zar = 'ZAR',
}
/** Country enum */
export enum CountryEnum {
  Pl = 'PL',
  Rsa = 'RSA',
  Sk = 'SK',
  Cz = 'CZ',
}
/** CompanyType enum */
export enum CompanyTypeEnum {
  Client = 'CLIENT',
  Affiliate = 'AFFILIATE',
  Brand = 'BRAND',
}
/** OrderOrigin enum */
export enum OrderOriginEnum {
  DameJidlo = 'DAME_JIDLO',
  Staff = 'STAFF',
  Wifi = 'WIFI',
  Baker = 'BAKER',
  Enigoo = 'ENIGOO',
  MobilAndroid = 'MOBIL_ANDROID',
  MobilIos = 'MOBIL_IOS',
  Mpizza = 'MPIZZA',
  Minisite = 'MINISITE',
  AffiliatePizzaRozvoz = 'AFFILIATE_PIZZA_ROZVOZ',
  AffiliateRozvozPizzyZdarma = 'AFFILIATE_ROZVOZ_PIZZY_ZDARMA',
  AffiliateRozvozJidla = 'AFFILIATE_ROZVOZ_JIDLA',
  CallCenter = 'CALL_CENTER',
  Pwa = 'PWA',
  WebMenu = 'WEB_MENU',
  WifiPreorder = 'WIFI_PREORDER',
  Web = 'WEB',
  WifiDefault = 'WIFI_DEFAULT',
  Default = 'DEFAULT',
  Wolt = 'WOLT',
  Bistro = 'BISTRO',
  WebPartner = 'WEB_PARTNER',
  JidloPodNos = 'JIDLO_POD_NOS',
}
/** WarePriceType enum */
export enum WarePriceTypeEnum {
  Delivery = 'DELIVERY',
  Inhouse = 'INHOUSE',
}
/** PaymentGate enum */
export enum PaymentGateEnum {
  Gopay = 'GOPAY',
  Paypal = 'PAYPAL',
  Credit = 'CREDIT',
  Cash = 'CASH',
  PayFast = 'PAY_FAST',
  Payu = 'PAYU',
  Csob = 'CSOB',
  GpWebpay = 'GP_WEBPAY',
}
/** PaymentType enum */
export enum PaymentTypeEnum {
  Cash = 'CASH',
  Credit = 'CREDIT',
  Voucher = 'VOUCHER',
  Discount = 'DISCOUNT',
  PostPaid = 'POST_PAID',
}
/** FieldState enum */
export enum FieldStateEnum {
  Default = 'DEFAULT',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}
/** DeliveryState enum */
export enum DeliveryStateEnum {
  Fresh = 'FRESH',
  OnWay = 'ON_WAY',
  Delivered = 'DELIVERED',
  Canceled = 'CANCELED',
  DriverChanged = 'DRIVER_CHANGED',
  NotFound = 'NOT_FOUND',
  LateDelivery = 'LATE_DELIVERY',
  Reclamation = 'RECLAMATION',
  Accepted = 'ACCEPTED',
  DriverRejected = 'DRIVER_REJECTED',
  LastMileDelivery = 'LAST_MILE_DELIVERY',
}
/** OrderState enum */
export enum OrderStateEnum {
  InCart = 'IN_CART',
  Received = 'RECEIVED',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
  Reclamation = 'RECLAMATION',
  ReclamationHandled = 'RECLAMATION_HANDLED',
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  ByCreditCard = 'BY_CREDIT_CARD',
  Refund = 'REFUND',
  ShouldBePaid = 'SHOULD_BE_PAID',
  CustomerNotified = 'CUSTOMER_NOTIFIED',
  New = 'NEW',
  Online = 'ONLINE',
  Evaluated = 'EVALUATED',
  NotEvaluated = 'NOT_EVALUATED',
  Shown = 'SHOWN',
  Accepted = 'ACCEPTED',
  ReadyToProduce = 'READY_TO_PRODUCE',
  InProduce = 'IN_PRODUCE',
  Made = 'MADE',
  OnWay = 'ON_WAY',
  StuckOnRoad = 'STUCK_ON_ROAD',
  Late = 'LATE',
  Delivered = 'DELIVERED',
  Cause = 'CAUSE',
  Handover = 'HANDOVER',
  WaitingForCustomer = 'WAITING_FOR_CUSTOMER',
  CustomerDidNotCome = 'CUSTOMER_DID_NOT_COME',
  DeliverRejected = 'DELIVER_REJECTED',
  CancelOnWay = 'CANCEL_ON_WAY',
  Canceled = 'CANCELED',
  BranchCancel = 'BRANCH_CANCEL',
  CustomerCancel = 'CUSTOMER_CANCEL',
  ReadyToDeliver = 'READY_TO_DELIVER',
  BillPassed = 'BILL_PASSED',
  BillPrinted = 'BILL_PRINTED',
  KitchenTicketPrinted = 'KITCHEN_TICKET_PRINTED',
  HighPriority = 'HIGH_PRIORITY',
  WifiDelayed = 'WIFI_DELAYED',
  AssignedToDeliver = 'ASSIGNED_TO_DELIVER',
  TransferCancel = 'TRANSFER_CANCEL',
}
/** OrderBillingType enum */
export enum OrderBillingTypeEnum {
  PriceSum = 'PRICE_SUM',
  Commission = 'COMMISSION',
  Fee = 'FEE',
  RewardResult = 'REWARD_RESULT',
  Affiliate = 'AFFILIATE',
}
/** OperatingCostFrequency enum */
export enum OperatingCostFrequencyEnum {
  Automatic = 'AUTOMATIC',
  Month = 'MONTH',
  OnDemand = 'ON_DEMAND',
}
/** OperatingCostType enum */
export enum OperatingCostTypeEnum {
  Tmobile = 'TMOBILE',
  Axima = 'AXIMA',
  Nethost = 'NETHOST',
  Gopay = 'GOPAY',
  Payu = 'PAYU',
  PayFast = 'PAY_FAST',
  Csob = 'CSOB',
  Basic = 'BASIC',
  Pickup = 'PICKUP',
  Inhouse = 'INHOUSE',
  Messenger = 'MESSENGER',
  Cash = 'CASH',
  Credit = 'CREDIT',
  SpeedloService = 'SPEEDLO_SERVICE',
  Commission = 'COMMISSION',
  GpWebpay = 'GP_WEBPAY',
}
/** PromoCodeType enum */
export enum PromoCodeTypeEnum {
  PromoCode = 'PROMO_CODE',
  Voucher = 'VOUCHER',
}
/** OrderRecipeState enum */
export enum OrderRecipeStateEnum {
  Fresh = 'FRESH',
  Preparing = 'PREPARING',
  Cooked = 'COOKED',
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Ready = 'READY',
  Dispatched = 'DISPATCHED',
}
/** PromoTag enum */
export enum PromoTagEnum {
  PromoCart = 'PROMO_CART',
  PromoHeader = 'PROMO_HEADER',
  PromoSideDish = 'PROMO_SIDE_DISH',
  HiddenFromStandard = 'HIDDEN_FROM_STANDARD',
  PromoFooter = 'PROMO_FOOTER',
  PromoCategoryList = 'PROMO_CATEGORY_LIST',
}
/** OrderOnlineState enum */
export enum OrderOnlineStateEnum {
  Created = 'CREATED',
  Pending = 'PENDING',
  Paid = 'PAID',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Refunded = 'REFUNDED',
}
/** SectorType enum */
export enum SectorTypeEnum {
  Area = 'AREA',
  Table = 'TABLE',
}
/** ZoneType enum */
export enum ZoneTypeEnum {
  Area = 'AREA',
  Table = 'TABLE',
  Sector = 'SECTOR',
  Return = 'RETURN',
}
/** DeviceState enum */
export enum DeviceStateEnum {
  Working = 'WORKING',
  Broken = 'BROKEN',
  Free = 'FREE',
  Busy = 'BUSY',
}
/** DeviceType enum */
export enum DeviceTypeEnum {
  Car = 'CAR',
  Plane = 'PLANE',
  Dron = 'DRON',
}
/** Possible roles for user */
export enum RoleEnum {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Support = 'SUPPORT',
  Editor = 'EDITOR',
  EditorOrder = 'EDITOR_ORDER',
  EditorMenu = 'EDITOR_MENU',
  EditorZone = 'EDITOR_ZONE',
  Merchant = 'MERCHANT',
  Operator = 'OPERATOR',
  Cashier = 'CASHIER',
  CashierMenu = 'CASHIER_MENU',
  Accountant = 'ACCOUNTANT',
  BranchOwner = 'BRANCH_OWNER',
  Supervisor = 'SUPERVISOR',
  BrandOwner = 'BRAND_OWNER',
  User = 'USER',
  Guest = 'GUEST',
  Customer = 'CUSTOMER',
  EditorBusinessHour = 'EDITOR_BUSINESS_HOUR',
  ExternalApi = 'EXTERNAL_API',
  Extern = 'EXTERN',
  EditorCompany = 'EDITOR_COMPANY',
  EditorCompanyBranch = 'EDITOR_COMPANY_BRANCH',
  EditorTransportationDevice = 'EDITOR_TRANSPORTATION_DEVICE',
  EditorWare = 'EDITOR_WARE',
  EditorUser = 'EDITOR_USER',
  EditorWareCategory = 'EDITOR_WARE_CATEGORY',
  EditorShift = 'EDITOR_SHIFT',
  EditorIngredient = 'EDITOR_INGREDIENT',
  EditorRecipe = 'EDITOR_RECIPE',
  EditorOrderRecipe = 'EDITOR_ORDER_RECIPE',
  EditorStockItem = 'EDITOR_STOCK_ITEM',
  EditorAddress = 'EDITOR_ADDRESS',
  EditorAnnouncement = 'EDITOR_ANNOUNCEMENT',
  EditorAvailability = 'EDITOR_AVAILABILITY',
  EditorCompanyBranchCurrency = 'EDITOR_COMPANY_BRANCH_CURRENCY',
  EditorCompanyBranchDelivery = 'EDITOR_COMPANY_BRANCH_DELIVERY',
  EditorCompanyBranchUser = 'EDITOR_COMPANY_BRANCH_USER',
  EditorCompanyOrderOrigin = 'EDITOR_COMPANY_ORDER_ORIGIN',
  EditorEvent = 'EDITOR_EVENT',
  EditorImportLog = 'EDITOR_IMPORT_LOG',
  EditorLabel = 'EDITOR_LABEL',
  EditorLabelTranslation = 'EDITOR_LABEL_TRANSLATION',
  EditorNote = 'EDITOR_NOTE',
  EditorOrderBilling = 'EDITOR_ORDER_BILLING',
  EditorOrderPaymentMethod = 'EDITOR_ORDER_PAYMENT_METHOD',
  EditorOrderStateRelation = 'EDITOR_ORDER_STATE_RELATION',
  EditorPageElement = 'EDITOR_PAGE_ELEMENT',
  EditorPaymentMethod = 'EDITOR_PAYMENT_METHOD',
  EditorPaymentMethodBranch = 'EDITOR_PAYMENT_METHOD_BRANCH',
  EditorStockItemAllergen = 'EDITOR_STOCK_ITEM_ALLERGEN',
  EditorRecipePrice = 'EDITOR_RECIPE_PRICE',
  EditorRole = 'EDITOR_ROLE',
  EditorSetting = 'EDITOR_SETTING',
  EditorShiftUser = 'EDITOR_SHIFT_USER',
  EditorSorting = 'EDITOR_SORTING',
  EditorTransportationDeviceUser = 'EDITOR_TRANSPORTATION_DEVICE_USER',
  EditorUserDeliveringOrder = 'EDITOR_USER_DELIVERING_ORDER',
  EditorUserLoginProvider = 'EDITOR_USER_LOGIN_PROVIDER',
  EditorUserPersonal = 'EDITOR_USER_PERSONAL',
  EditorWareCategoryRelation = 'EDITOR_WARE_CATEGORY_RELATION',
  EditorCountry = 'EDITOR_COUNTRY',
  EditorCurrency = 'EDITOR_CURRENCY',
  EditorRejectedPaymentMethod = 'EDITOR_REJECTED_PAYMENT_METHOD',
  EditorRecipeRelation = 'EDITOR_RECIPE_RELATION',
  EditorOrderOriginPriceType = 'EDITOR_ORDER_ORIGIN_PRICE_TYPE',
  EditorStockTransfer = 'EDITOR_STOCK_TRANSFER',
  Graphiql = 'GRAPHIQL',
  EditorOrders = 'EDITOR_ORDERS',
  EditorBillings = 'EDITOR_BILLINGS',
  EditorZones = 'EDITOR_ZONES',
  EditorBusinessHours = 'EDITOR_BUSINESS_HOURS',
  Vam = 'VAM',
  AccessConfig = 'ACCESS_CONFIG',
  EditorCashBox = 'EDITOR_CASH_BOX',
  Driver = 'DRIVER',
  EditorFinancialReport = 'EDITOR_FINANCIAL_REPORT',
  EditorCountryVat = 'EDITOR_COUNTRY_VAT',
  EditorCountryVatTypePrice = 'EDITOR_COUNTRY_VAT_TYPE_PRICE',
  EditorOrderDiscount = 'EDITOR_ORDER_DISCOUNT',
  SuspendedUser = 'SUSPENDED_USER',
  EditorPromoCode = 'EDITOR_PROMO_CODE',
  CallCenter = 'CALL_CENTER',
  CashierDelivery = 'CASHIER_DELIVERY',
  CashierStatistics = 'CASHIER_STATISTICS',
  CashierReports = 'CASHIER_REPORTS',
  EditorOrderPriceModification = 'EDITOR_ORDER_PRICE_MODIFICATION',
  CashierPaymentMethods = 'CASHIER_PAYMENT_METHODS',
  CashierChangeDeliveryType = 'CASHIER_CHANGE_DELIVERY_TYPE',
  CashierTransferCompanyBranch = 'CASHIER_TRANSFER_COMPANY_BRANCH',
  DriverLiftago = 'DRIVER_LIFTAGO',
  Liftago = 'LIFTAGO',
  RequestCancel = 'REQUEST_CANCEL',
  DriverProrozvoz = 'DRIVER_PROROZVOZ',
  CashierUnlimited = 'CASHIER_UNLIMITED',
  OwnerCancel = 'OWNER_CANCEL',
  CashierUnlimitedCustomer = 'CASHIER_UNLIMITED_CUSTOMER',
  DriverFoodis = 'DRIVER_FOODIS',
  CashierCancel = 'CASHIER_CANCEL',
  DriverDefault = 'DRIVER_DEFAULT',
  SalesRepresentative = 'SALES_REPRESENTATIVE',
  DriverDodo = 'DRIVER_DODO',
  CashierBasicRecipes = 'CASHIER_BASIC_RECIPES',
  CashierIsolatedPrint = 'CASHIER_ISOLATED_PRINT',
}
/** ShiftUserState enum */
export enum ShiftUserStateEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  WaitingForResponse = 'WAITING_FOR_RESPONSE',
}
/** RecipeTagCategoryType enum */
export enum RecipeTagCategoryTypeEnum {
  WareType = 'WARE_TYPE',
  WareProperty = 'WARE_PROPERTY',
  SpecialFlag = 'SPECIAL_FLAG',
}
/** WareCategoryType enum */
export enum WareCategoryTypeEnum {
  Main = 'MAIN',
  SideDish = 'SIDE_DISH',
  Cover = 'COVER',
  Tip = 'TIP',
  Mixin = 'MIXIN',
}
/** AnalyticsCodeType enum */
export enum AnalyticsCodeTypeEnum {
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  FacebookPixel = 'FACEBOOK_PIXEL',
  SklikConversion = 'SKLIK_CONVERSION',
}
/** PrinterType enum */
export enum PrinterTypeEnum {
  W58Mm = 'W58MM',
  W80Mm = 'W80MM',
}
/** PrintableType enum */
export enum PrintableTypeEnum {
  Order = 'ORDER',
  FinancialReport = 'FINANCIAL_REPORT',
  Note = 'NOTE',
}
/** PrintTemplateType enum */
export enum PrintTemplateTypeEnum {
  KitchenTicket = 'KITCHEN_TICKET',
  OrderTicket = 'ORDER_TICKET',
  FinancialReportBranch = 'FINANCIAL_REPORT_BRANCH',
}
/** TriggerableType enum */
export enum TriggerableTypeEnum {
  Order = 'ORDER',
  FinancialReport = 'FINANCIAL_REPORT',
  Note = 'NOTE',
}
/** SmsTemplateType enum */
export enum SmsTemplateTypeEnum {
  OrderReceived = 'ORDER_RECEIVED',
  OrderAccepted = 'ORDER_ACCEPTED',
  OrderInProduce = 'ORDER_IN_PRODUCE',
  OrderReadyToDeliver = 'ORDER_READY_TO_DELIVER',
  OrderOnWay = 'ORDER_ON_WAY',
  OrderFinished = 'ORDER_FINISHED',
  OrderCanceled = 'ORDER_CANCELED',
}
/** TriggerType enum */
export enum TriggerTypeEnum {
  Print = 'PRINT',
  Sms = 'SMS',
  Mail = 'MAIL',
}
/** FileReportType enum */
export enum FileReportTypeEnum {
  OrdersDetails = 'ORDERS_DETAILS',
  DailyVat = 'DAILY_VAT',
  Sales = 'SALES',
  StockManipulations = 'STOCK_MANIPULATIONS',
}
/** PrintQueueState enum */
export enum PrintQueueStateEnum {
  Fresh = 'FRESH',
  Printed = 'PRINTED',
  Failure = 'FAILURE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
}
/** SettingType enum */
export enum SettingTypeEnum {
  Confirmation = 'CONFIRMATION',
  AdminOrderListing = 'ADMIN_ORDER_LISTING',
  Rules = 'RULES',
  Url = 'URL',
  Graphic = 'GRAPHIC',
  Options = 'OPTIONS',
}
/** Possible time precision types */
export enum TimePrecisionTypeEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}
/** StockItemChangeType enum */
export enum StockItemChangeTypeEnum {
  StockDown = 'STOCK_DOWN',
  StockSale = 'STOCK_SALE',
  Damage = 'DAMAGE',
  Expired = 'EXPIRED',
  SoldOutsideSystem = 'SOLD_OUTSIDE_SYSTEM',
  Stolen = 'STOLEN',
  Tasting = 'TASTING',
  StockUp = 'STOCK_UP',
  StockTransfer = 'STOCK_TRANSFER',
}
/** StornoRequestType enum */
export enum StornoRequestTypeEnum {
  CustomerStorned = 'CUSTOMER_STORNED',
  MistakeInOrder = 'MISTAKE_IN_ORDER',
  TechnicalDifficulties = 'TECHNICAL_DIFFICULTIES',
  CustomerUnavailable = 'CUSTOMER_UNAVAILABLE',
  Closed = 'CLOSED',
  Duplicate = 'DUPLICATE',
  ThirdParty = 'THIRD_PARTY',
  Other = 'OTHER',
}
/** Possible order rating types */
export enum OrderRatingTypeEnum {
  Rating = 'RATING',
  RatingFood = 'RATING_FOOD',
  RatingOrder = 'RATING_ORDER',
  RatingDelivery = 'RATING_DELIVERY',
}
/** Possible attributes which may change in updateOrderPreview */
export enum OrderDiffAttributeEnum {
  CompanyBranch = 'COMPANY_BRANCH',
  DeliveryType = 'DELIVERY_TYPE',
  DeliverySlot = 'DELIVERY_SLOT',
  DeliverAt = 'DELIVER_AT',
  PriceTotalSum = 'PRICE_TOTAL_SUM',
  PriceTransportFee = 'PRICE_TRANSPORT_FEE',
  PriceTip = 'PRICE_TIP',
  PriceExtraCharge = 'PRICE_EXTRA_CHARGE',
  OrderRecipeMain = 'ORDER_RECIPE_MAIN',
  OrderRecipeSideDish = 'ORDER_RECIPE_SIDE_DISH',
}
/** Possible change types of OrderDiff */
export enum OrderDiffChangeTypeEnum {
  Update = 'UPDATE',
  Remove = 'REMOVE',
  Add = 'ADD',
}
/** WareRequirement enum */
export enum WareRequirementEnum {
  NoLimit = 'NO_LIMIT',
  Optional = 'OPTIONAL',
  MaxOne = 'MAX_ONE',
  Mandatory = 'MANDATORY',
}
/** Possible action types of subscription */
export enum SubscriptionActionType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Destroy = 'DESTROY',
  Remove = 'REMOVE',
}

/** Date in format YYYY-MM-DD */

/** Time without date (HH:MM:SS) */
export type Time = string

/** Date in format iso 8601 format */
export type DateTime = Date

/** Data in json format */
export type Json = { [key: string]: string | number }

/** Input file type for Apollo upload using multipart requests */
export type InputFile = any

// ====================================================
// Documents
// ====================================================

export type QBusinessLookupVariables = {
  readonly url?: Maybe<string>
}

export type QBusinessLookupQuery = {
  readonly __typename?: 'Query'

  readonly lookup: QBusinessLookupLookup
}

export type QBusinessLookupLookup = {
  readonly __typename?: 'CompanyBrandLookUp'

  readonly branch: Maybe<QBusinessLookupBranch>

  readonly redirectUrl: Maybe<string>

  readonly isBrand: boolean

  readonly isMobileApp: boolean

  readonly knownBasePath: string

  readonly orderOrigin: OrderOriginEnum

  readonly defaultLanguage: LanguageEnum

  readonly analytics: ReadonlyArray<QBusinessLookupAnalytics>
}

export type QBusinessLookupBranch = FCompanyBranchFragment

export type QBusinessLookupAnalytics = {
  readonly __typename?: 'AnalyticsCode'

  readonly code: Maybe<string>

  readonly type: QBusinessLookupType
}

export type QBusinessLookupType = {
  readonly __typename?: 'AnalyticsCodeType'

  readonly enum: AnalyticsCodeTypeEnum
}

export type QDelayedOrderVariables = {
  readonly branchId: string
}

export type QDelayedOrderQuery = {
  readonly __typename?: 'Query'

  readonly delayedOrder: QDelayedOrderDelayedOrder
}

export type QDelayedOrderDelayedOrder = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly intervals: ReadonlyArray<QDelayedOrderIntervals>

  readonly enabled: boolean
} & FCompanyBranchStatusFragment

export type QDelayedOrderIntervals = {
  readonly __typename?: 'BusinessHourInterval'

  readonly start: Date

  readonly end: Date
}

export type QThemeStyleVariables = {
  readonly brandId?: Maybe<string>
  readonly branchId?: Maybe<string>
}

export type QThemeStyleQuery = {
  readonly __typename?: 'Query'

  readonly theme: QThemeStyleTheme
}

export type QThemeStyleTheme = {
  readonly __typename?: 'ApplicationSettings'

  readonly styles: QThemeStyleStyles
}

export type QThemeStyleStyles = {
  readonly __typename?: 'GraphicStyle'

  readonly active: string

  readonly main: string

  readonly mainSecondary: string

  readonly storeLogo: string

  readonly storeLogoMobile: string

  readonly backgroundCart: string
}

export type MAddToCartVariables = {
  readonly orderId: string
  readonly recipeId: string
  readonly sideDishes?: Maybe<ReadonlyArray<string>>
  readonly withGoods: boolean
  readonly withPresence: boolean
}

export type MAddToCartMutation = {
  readonly __typename?: 'Mutation'

  readonly add: MAddToCartAdd
}

export type MAddToCartAdd = FCartSummaryFragment &
  FCartGoodsFragment &
  FCartPresenceFragment

export type MChangeCartItemVariables = {
  readonly orderId: string
  readonly itemId: string
  readonly sideDishes?: Maybe<ReadonlyArray<string>>
  readonly withGoods: boolean
}

export type MChangeCartItemMutation = {
  readonly __typename?: 'Mutation'

  readonly change: MChangeCartItemChange
}

export type MChangeCartItemChange = FCartSummaryFragment & FCartGoodsFragment

export type MRemoveCartItemVariables = {
  readonly itemId: string
  readonly withGoods: boolean
  readonly withPresence: boolean
}

export type MRemoveCartItemMutation = {
  readonly __typename?: 'Mutation'

  readonly remove: MRemoveCartItemRemove
}

export type MRemoveCartItemRemove = FCartSummaryFragment &
  FCartGoodsFragment &
  FCartPresenceFragment

export type QCartVariables = {
  readonly orderId: string
  readonly withGoods: boolean
  readonly withPresence: boolean
}

export type QCartQuery = {
  readonly __typename?: 'Query'

  readonly order: QCartOrder
}

export type QCartOrder = FCartSummaryFragment &
  FCartGoodsFragment &
  FCartPresenceFragment &
  FCartStaticFragment

export type QCartItemVariables = {
  readonly itemId: string
}

export type QCartItemQuery = {
  readonly __typename?: 'Query'

  readonly item: QCartItemItem
}

export type QCartItemItem = {
  readonly __typename?: 'OrderRecipe'

  readonly id: string

  readonly recipe: QCartItemRecipe

  readonly sideDishes: ReadonlyArray<QCartItemSideDishes>
}

export type QCartItemRecipe = FRecipeDetailFragment

export type QCartItemSideDishes = {
  readonly __typename?: 'OrderRecipe'

  readonly id: string

  readonly recipe: QCartItem_Recipe
}

export type QCartItem_Recipe = {
  readonly __typename?: 'Recipe'

  readonly id: string
}

export type QCartRecipeVariables = {
  readonly recipeId: string
  readonly branchId?: Maybe<string>
}

export type QCartRecipeQuery = {
  readonly __typename?: 'Query'

  readonly recipe: QCartRecipeRecipe
}

export type QCartRecipeRecipe = FRecipeDetailFragment

export type MCreateOrderRatingVariables = {
  readonly orderId: string
  readonly ratings: ReadonlyArray<InputOrderRatingCreate>
}

export type MCreateOrderRatingMutation = {
  readonly __typename?: 'Mutation'

  readonly createOrderRating: MCreateOrderRatingCreateOrderRating
}

export type MCreateOrderRatingCreateOrderRating = {
  readonly __typename?: 'Order'

  readonly id: string
}

export type QOrderForFeedbackVariables = {
  readonly orderId: string
}

export type QOrderForFeedbackQuery = {
  readonly __typename?: 'Query'

  readonly order: QOrderForFeedbackOrder
}

export type QOrderForFeedbackOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly hasRating: boolean

  readonly companyBranch: QOrderForFeedbackCompanyBranch
}

export type QOrderForFeedbackCompanyBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly phone: string
}

export type QOrderFinishVariables = {
  readonly orderId: string
}

export type QOrderFinishQuery = {
  readonly __typename?: 'Query'

  readonly order: QOrderFinishOrder
}

export type QOrderFinishOrder = {
  readonly __typename?: 'Order'

  readonly companyBranch: QOrderFinishCompanyBranch
} & FOrderFinishedFragment

export type QOrderFinishCompanyBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly phone: string

  readonly company: QOrderFinishCompany

  readonly androidApp: Maybe<QOrderFinishAndroidApp>

  readonly iosApp: Maybe<QOrderFinishIosApp>
}

export type QOrderFinishCompany = {
  readonly __typename?: 'Company'

  readonly id: string
}

export type QOrderFinishAndroidApp = {
  readonly __typename?: 'MobileApplicationSetting'

  readonly url: string
}

export type QOrderFinishIosApp = {
  readonly __typename?: 'MobileApplicationSetting'

  readonly url: string
}

export type QOrderStateVariables = {
  readonly orderId: string
}

export type QOrderStateQuery = {
  readonly __typename?: 'Query'

  readonly order: QOrderStateOrder
}

export type QOrderStateOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly deliveryType: Maybe<QOrderStateDeliveryType>

  readonly orderStateCategory: OrderStateCategoryEnum

  readonly companyBranch: QOrderStateCompanyBranch

  readonly transferredIntoOrder: Maybe<QOrderStateTransferredIntoOrder>
}

export type QOrderStateDeliveryType = {
  readonly __typename?: 'DeliveryType'

  readonly enum: DeliveryTypeEnum
}

export type QOrderStateCompanyBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly allowedCategories: ReadonlyArray<OrderStateCategoryEnum>
}

export type QOrderStateTransferredIntoOrder = {
  readonly __typename?: 'Order'

  readonly id: string
}

export type SUpdateOrderVariables = {
  readonly orderId: string
  readonly companyBranches: ReadonlyArray<string>
}

export type SUpdateOrderSubscription = {
  readonly __typename?: 'Subscription'

  readonly updatedOrder: SUpdateOrderUpdatedOrder
}

export type SUpdateOrderUpdatedOrder = {
  readonly __typename?: 'OrderUpdateSubscription'

  readonly order: Maybe<SUpdateOrderOrder>
}

export type SUpdateOrderOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly deliveryType: Maybe<SUpdateOrderDeliveryType>

  readonly orderStateCategory: OrderStateCategoryEnum

  readonly transferredIntoOrder: Maybe<SUpdateOrderTransferredIntoOrder>
}

export type SUpdateOrderDeliveryType = {
  readonly __typename?: 'DeliveryType'

  readonly enum: DeliveryTypeEnum
}

export type SUpdateOrderTransferredIntoOrder = {
  readonly __typename?: 'Order'

  readonly id: string
}

export type QFoodMenuVariables = {
  readonly branchId: string
}

export type QFoodMenuQuery = {
  readonly __typename?: 'Query'

  readonly wareCategories: ReadonlyArray<QFoodMenuWareCategories>
}

export type QFoodMenuWareCategories = {
  readonly __typename?: 'WareCategory'

  readonly recipes: ReadonlyArray<QFoodMenuRecipes>
} & FFoodMenuCategoryFragment

export type QFoodMenuRecipes = FFoodMenuRecipeFragment

export type QFoodMenuCartPresenceVariables = {
  readonly orderId: string
}

export type QFoodMenuCartPresenceQuery = {
  readonly __typename?: 'Query'

  readonly order: QFoodMenuCartPresenceOrder
}

export type QFoodMenuCartPresenceOrder = {
  readonly __typename?: 'Order'

  readonly id: string
} & FCartPresenceFragment

export type MDeliveryOrderCreateVariables = {
  readonly brandId?: Maybe<string>
  readonly branchId?: Maybe<string>
  readonly gps: InputGeoPointCreate
  readonly orderOrigin: OrderOriginEnum
  readonly address?: Maybe<string>
}

export type MDeliveryOrderCreateMutation = {
  readonly __typename?: 'Mutation'

  readonly order: MDeliveryOrderCreateOrder
}

export type MDeliveryOrderCreateOrder = FOrderCreateFragment

export type MPickupOrderCreateVariables = {
  readonly branchId: string
  readonly orderOrigin: OrderOriginEnum
}

export type MPickupOrderCreateMutation = {
  readonly __typename?: 'Mutation'

  readonly order: MPickupOrderCreateOrder
}

export type MPickupOrderCreateOrder = FOrderCreateFragment

export type QInitialDeliveryLocationVariables = {}

export type QInitialDeliveryLocationQuery = {
  readonly __typename?: 'Query'

  readonly location: QInitialDeliveryLocationLocation
}

export type QInitialDeliveryLocationLocation = {
  readonly __typename?: 'AddressResolvedLocation'

  readonly gpsCoordinates: Maybe<QInitialDeliveryLocationGpsCoordinates>
}

export type QInitialDeliveryLocationGpsCoordinates = {
  readonly __typename?: 'GeoPoint'

  readonly latitude: number

  readonly longitude: number
}

export type QInitialLocationVariables = {}

export type QInitialLocationQuery = {
  readonly __typename?: 'Query'

  readonly initialLocation: QInitialLocationInitialLocation
}

export type QInitialLocationInitialLocation = {
  readonly __typename?: 'AddressResolvedLocation'

  readonly gpsCoordinates: Maybe<QInitialLocationGpsCoordinates>
}

export type QInitialLocationGpsCoordinates = {
  readonly __typename?: 'GeoPoint'

  readonly latitude: number

  readonly longitude: number
}

export type QPickupMapVariables = {
  readonly branchSpecific: boolean
  readonly brandId?: Maybe<string>
  readonly branchId: string
}

export type QPickupMapQuery = {
  readonly __typename?: 'Query'

  readonly brand: ReadonlyArray<QPickupMapBrand>

  readonly branch: QPickupMapBranch
}

export type QPickupMapBrand = FPickupBranchFragment

export type QPickupMapBranch = FPickupBranchFragment

export type QResolveLocationVariables = {
  readonly brandId?: Maybe<string>
  readonly branchId?: Maybe<string>
  readonly address?: Maybe<string>
  readonly latitude?: Maybe<number>
  readonly longitude?: Maybe<number>
}

export type QResolveLocationQuery = {
  readonly __typename?: 'Query'

  readonly resolveLocation: QResolveLocationResolveLocation
}

export type QResolveLocationResolveLocation = {
  readonly __typename?: 'AddressResolvedLocation'

  readonly finalAddress: Maybe<QResolveLocationFinalAddress>

  readonly isResolved: boolean

  readonly isValidAddress: boolean

  readonly inDeliveryLocation: boolean

  readonly gpsCoordinates: Maybe<QResolveLocationGpsCoordinates>

  readonly companyBranch: Maybe<QResolveLocationCompanyBranch>

  readonly zone: Maybe<QResolveLocationZone>
}

export type QResolveLocationFinalAddress = FJsonAddressFragment

export type QResolveLocationGpsCoordinates = {
  readonly __typename?: 'GeoPoint'

  readonly latitude: number

  readonly longitude: number
}

export type QResolveLocationCompanyBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly isOpen: boolean
}

export type QResolveLocationZone = {
  readonly __typename?: 'Zone'

  readonly id: string

  readonly deliveryTimeDeviation: number
}

export type QMobileShopIntroVariables = {
  readonly brandId?: Maybe<string>
  readonly branchId?: Maybe<string>
}

export type QMobileShopIntroQuery = {
  readonly __typename?: 'Query'

  readonly intro: QMobileShopIntroIntro
}

export type QMobileShopIntroIntro = {
  readonly __typename?: 'ApplicationSettings'

  readonly headerText: Maybe<string>

  readonly homepageUrl: Maybe<string>
}

export type QMobileShopStatusVariables = {
  readonly branchId: string
}

export type QMobileShopStatusQuery = {
  readonly __typename?: 'Query'

  readonly branch: QMobileShopStatusBranch
}

export type QMobileShopStatusBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string
} & FCompanyBranchStatusFragment

export type QSmartBannerVariables = {
  readonly branchId: string
  readonly platform: MobilePlatformEnum
}

export type QSmartBannerQuery = {
  readonly __typename?: 'Query'

  readonly app: Maybe<QSmartBannerApp>
}

export type QSmartBannerApp = {
  readonly __typename?: 'MobileApplicationSetting'

  readonly title: string

  readonly url: string

  readonly icon: string
}

export type MOrderDeliveryTimeVariables = {
  readonly orderId: string
  readonly deliverAt?: Maybe<Date>
  readonly withGoods: boolean
}

export type MOrderDeliveryTimeMutation = {
  readonly __typename?: 'Mutation'

  readonly updateOrder: MOrderDeliveryTimeUpdateOrder
}

export type MOrderDeliveryTimeUpdateOrder = FCartSummaryFragment &
  FCartGoodsFragment

export type MOrderPromoAddVariables = {
  readonly orderId: string
  readonly code: string
  readonly withGoods: boolean
}

export type MOrderPromoAddMutation = {
  readonly __typename?: 'Mutation'

  readonly addOrderPromoCode: MOrderPromoAddAddOrderPromoCode
}

export type MOrderPromoAddAddOrderPromoCode = {
  readonly __typename?: 'OrderWithErrors'

  readonly userErrors: ReadonlyArray<string>

  readonly order: MOrderPromoAddOrder
}

export type MOrderPromoAddOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly promoCodes: ReadonlyArray<MOrderPromoAddPromoCodes>
} & (FCartSummaryFragment & FCartGoodsFragment)

export type MOrderPromoAddPromoCodes = FPromoCodeFragment

export type MOrderPromoRemoveVariables = {
  readonly orderId: string
  readonly codeId: string
  readonly withGoods: boolean
}

export type MOrderPromoRemoveMutation = {
  readonly __typename?: 'Mutation'

  readonly removeOrderPromoCode: MOrderPromoRemoveRemoveOrderPromoCode
}

export type MOrderPromoRemoveRemoveOrderPromoCode = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly promoCodes: ReadonlyArray<MOrderPromoRemovePromoCodes>
} & (FCartSummaryFragment & FCartGoodsFragment)

export type MOrderPromoRemovePromoCodes = FPromoCodeFragment

export type MOrderTipUpdateVariables = {
  readonly orderId: string
  readonly tips: number
  readonly withGoods: boolean
}

export type MOrderTipUpdateMutation = {
  readonly __typename?: 'Mutation'

  readonly updateOrder: MOrderTipUpdateUpdateOrder
}

export type MOrderTipUpdateUpdateOrder = FCartSummaryFragment &
  FCartGoodsFragment

export type MSubmitOrderVariables = {
  readonly orderId: string
  readonly customer?: Maybe<InputCustomerCreate>
  readonly note?: Maybe<string>
  readonly tips?: Maybe<number>
  readonly paymentGate: PaymentGateEnum
  readonly deliverAt?: Maybe<Date>
  readonly agreements?: Maybe<ReadonlyArray<string>>
  readonly returnUrl?: Maybe<string>
}

export type MSubmitOrderMutation = {
  readonly __typename?: 'Mutation'

  readonly submitOrder: MSubmitOrderSubmitOrder
}

export type MSubmitOrderSubmitOrder = {
  readonly __typename?: 'FinishedOrder'

  readonly userErrors: ReadonlyArray<string>

  readonly gopayUrl: Maybe<string>

  readonly payuUrl: Maybe<string>

  readonly csobUrl: Maybe<string>
}

export type QOrderFormVariables = {
  readonly orderId: string
}

export type QOrderFormQuery = {
  readonly __typename?: 'Query'

  readonly order: QOrderFormOrder
}

export type QOrderFormOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly countWares: number

  readonly orderStateCategory: OrderStateCategoryEnum
}

export type QOrderFormAgreementsVariables = {
  readonly input: InputVamStatus
}

export type QOrderFormAgreementsQuery = {
  readonly __typename?: 'Query'

  readonly agreements: ReadonlyArray<QOrderFormAgreementsAgreements>
}

export type QOrderFormAgreementsAgreements = {
  readonly __typename?: 'VAMAgreement'

  readonly id: string

  readonly name: string

  readonly description: string

  readonly isConfirmed: boolean

  readonly isMandatory: boolean
}

export type QOrderPaymentStatusVariables = {
  readonly orderId: string
}

export type QOrderPaymentStatusQuery = {
  readonly __typename?: 'Query'

  readonly status: QOrderPaymentStatusStatus
}

export type QOrderPaymentStatusStatus = {
  readonly __typename?: 'OrderOnlinePaymentStatus'

  readonly isSuccess: boolean

  readonly userErrors: ReadonlyArray<string>
}

export type QOrderPromoCodesVariables = {
  readonly orderId: string
}

export type QOrderPromoCodesQuery = {
  readonly __typename?: 'Query'

  readonly order: QOrderPromoCodesOrder
}

export type QOrderPromoCodesOrder = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly promoCodes: ReadonlyArray<QOrderPromoCodesPromoCodes>
}

export type QOrderPromoCodesPromoCodes = FPromoCodeFragment

export type QPaymentMethodsVariables = {
  readonly branchId: string
  readonly orderOrigin: OrderOriginEnum
  readonly deliveryType: DeliveryTypeEnum
}

export type QPaymentMethodsQuery = {
  readonly __typename?: 'Query'

  readonly companyBranch: QPaymentMethodsCompanyBranch
}

export type QPaymentMethodsCompanyBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly paymentMethods: ReadonlyArray<QPaymentMethodsPaymentMethods>
}

export type QPaymentMethodsPaymentMethods = {
  readonly __typename?: 'PaymentMethodBranch'

  readonly paymentMethod: QPaymentMethodsPaymentMethod
}

export type QPaymentMethodsPaymentMethod = {
  readonly __typename?: 'PaymentMethod'

  readonly id: string

  readonly description: Maybe<string>

  readonly paymentGate: QPaymentMethodsPaymentGate
}

export type QPaymentMethodsPaymentGate = {
  readonly __typename?: 'PaymentGate'

  readonly enum: PaymentGateEnum

  readonly code: string
}

export type QShopHeaderBranchVariables = {
  readonly branchId: string
}

export type QShopHeaderBranchQuery = {
  readonly __typename?: 'Query'

  readonly branch: QShopHeaderBranchBranch
}

export type QShopHeaderBranchBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly address: QShopHeaderBranchAddress
} & (FShopOpeningHoursFragment & FCompanyBranchStatusFragment)

export type QShopHeaderBranchAddress = FAddressFragment

export type QShopHeaderIntroVariables = {
  readonly brandId?: Maybe<string>
  readonly branchId?: Maybe<string>
}

export type QShopHeaderIntroQuery = {
  readonly __typename?: 'Query'

  readonly intro: QShopHeaderIntroIntro
}

export type QShopHeaderIntroIntro = {
  readonly __typename?: 'ApplicationSettings'

  readonly headerText: Maybe<string>

  readonly homepageUrl: Maybe<string>
}

export type FCompanyBranchFragment = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly code: string

  readonly brand: FCompanyBranchBrand

  readonly company: FCompanyBranchCompany

  readonly address: FCompanyBranchAddress

  readonly url: string

  readonly phone: string

  readonly supportedLanguages: ReadonlyArray<LanguageEnum>
}

export type FCompanyBranchBrand = {
  readonly __typename?: 'Company'

  readonly id: string

  readonly name: string

  readonly code: string
}

export type FCompanyBranchCompany = {
  readonly __typename?: 'Company'

  readonly id: string
}

export type FCompanyBranchAddress = {
  readonly __typename?: 'Address'

  readonly id: string

  readonly country: Maybe<FCompanyBranchCountry>
}

export type FCompanyBranchCountry = {
  readonly __typename?: 'Country'

  readonly phonePrefix: string
}

export type FCompanyBranchStatusFragment = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly isOpen: boolean

  readonly willOpenAt: Maybe<Date>

  readonly willCloseAt: Maybe<Date>

  readonly businessHours: ReadonlyArray<FCompanyBranchStatusBusinessHours>

  readonly currentInterval: Maybe<FCompanyBranchStatusCurrentInterval>

  readonly defaultDeliveryTimeEnabled: boolean

  readonly defaultPickupTimeEnabled: boolean
}

export type FCompanyBranchStatusBusinessHours = {
  readonly __typename?: 'BusinessHourInterval'

  readonly openAt: Date

  readonly closeAt: Date

  readonly closed: boolean

  readonly closedNote: Maybe<string>
}

export type FCompanyBranchStatusCurrentInterval = {
  readonly __typename?: 'BusinessHourInterval'

  readonly closedNote: Maybe<string>
}

export type FJsonAddressFragment = {
  readonly __typename?: 'JsonAddress'

  readonly street: Maybe<string>

  readonly houseNumber: Maybe<string>

  readonly city: Maybe<string>

  readonly state: Maybe<string>

  readonly venue: Maybe<string>
}

export type FAddressFragment = {
  readonly __typename?: 'Address'

  readonly id: string

  readonly location: Maybe<FAddressLocation>
}

export type FAddressLocation = FJsonAddressFragment

export type FGeoPointFragment = {
  readonly __typename?: 'GeoPoint'

  readonly latitude: number

  readonly longitude: number
}

export type FPriceFragment = {
  readonly __typename?: 'PriceConverted'

  readonly value: number

  readonly formattedValue: string
}

export type FRecipeFragment = {
  readonly __typename?: 'Recipe'

  readonly id: string

  readonly nameLabel: string

  readonly sideDishCount: number
}

export type FPromoCodeFragment = {
  readonly __typename?: 'PromoCode'

  readonly id: string

  readonly code: string
}

export type FCartGoodsFragment = {
  readonly __typename?: 'Order'

  readonly items: ReadonlyArray<FCartGoodsItems>

  readonly coverPrice: FCartGoodsCoverPrice

  readonly transportFee: FCartGoodsTransportFee

  readonly discounts: ReadonlyArray<FCartGoodsDiscounts>

  readonly vouchers: ReadonlyArray<FCartGoodsVouchers>

  readonly totalDiscount: FCartGoodsTotalDiscount

  readonly tip: FCartGoodsTip
}

export type FCartGoodsItems = {
  readonly __typename?: 'OrderRecipe'

  readonly automatic: boolean

  readonly price: FCartGoodsPrice

  readonly sideDishes: ReadonlyArray<FCartGoodsSideDishes>
} & FCartItemFragment

export type FCartGoodsPrice = FPriceFragment

export type FCartGoodsSideDishes = FCartItemFragment

export type FCartGoodsCoverPrice = FPriceFragment

export type FCartGoodsTransportFee = FPriceFragment

export type FCartGoodsDiscounts = {
  readonly __typename?: 'PriceWithDescription'

  readonly code: string

  readonly description: string

  readonly price: FCartGoods_Price
}

export type FCartGoods_Price = FPriceFragment

export type FCartGoodsVouchers = {
  readonly __typename?: 'PriceWithDescription'

  readonly code: string

  readonly description: string

  readonly price: FCartGoods__Price
}

export type FCartGoods__Price = FPriceFragment

export type FCartGoodsTotalDiscount = FPriceFragment

export type FCartGoodsTip = FPriceFragment

export type FCartItemFragment = {
  readonly __typename?: 'OrderRecipe'

  readonly id: string

  readonly recipe: FCartItemRecipe
}

export type FCartItemRecipe = FRecipeFragment

export type FCartStaticFragment = {
  readonly __typename?: 'Order'

  readonly minimalOrderPrice: Maybe<FCartStaticMinimalOrderPrice>

  readonly deliveryType: Maybe<FCartStaticDeliveryType>
}

export type FCartStaticMinimalOrderPrice = FPriceFragment

export type FCartStaticDeliveryType = {
  readonly __typename?: 'DeliveryType'

  readonly enum: DeliveryTypeEnum
}

export type FCartSummaryFragment = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly countWares: number

  readonly totalSum: FCartSummaryTotalSum
}

export type FCartSummaryTotalSum = FPriceFragment

export type FRecipeDetailFragment = {
  readonly __typename?: 'Recipe'

  readonly id: string

  readonly nameLabel: string

  readonly descriptionLabel: Maybe<string>

  readonly categories: ReadonlyArray<FRecipeDetailCategories>
}

export type FRecipeDetailCategories = {
  readonly __typename?: 'WareCategory'

  readonly id: string

  readonly nameLabel: string

  readonly minCount: number

  readonly maxCount: number

  readonly maxRecipesCount: number

  readonly sideDishes: ReadonlyArray<FRecipeDetailSideDishes>
}

export type FRecipeDetailSideDishes = {
  readonly __typename?: 'Recipe'

  readonly id: string

  readonly nameLabel: string

  readonly price: FRecipeDetailPrice
}

export type FRecipeDetailPrice = FPriceFragment

export type FOrderFinishedFragment = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly items: ReadonlyArray<FOrderFinishedItems>

  readonly totalSum: FOrderFinishedTotalSum

  readonly orderPayments: ReadonlyArray<FOrderFinishedOrderPayments>
}

export type FOrderFinishedItems = {
  readonly __typename?: 'OrderRecipe'

  readonly sideDishes: ReadonlyArray<FOrderFinishedSideDishes>
} & FCartItemFragment

export type FOrderFinishedSideDishes = FCartItemFragment

export type FOrderFinishedTotalSum = FPriceFragment

export type FOrderFinishedOrderPayments = {
  readonly __typename?: 'OrderPaymentMethod'

  readonly paymentMethod: FOrderFinishedPaymentMethod
}

export type FOrderFinishedPaymentMethod = {
  readonly __typename?: 'PaymentMethod'

  readonly paymentType: FOrderFinishedPaymentType
}

export type FOrderFinishedPaymentType = {
  readonly __typename?: 'PaymentType'

  readonly enum: PaymentTypeEnum
}

export type FCartPresenceFragment = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly presence: ReadonlyArray<FCartPresencePresence>
}

export type FCartPresencePresence = {
  readonly __typename?: 'OrderRecipe'

  readonly id: string

  readonly recipe: FCartPresenceRecipe
}

export type FCartPresenceRecipe = {
  readonly __typename?: 'Recipe'

  readonly id: string
}

export type FFoodMenuCategoryFragment = {
  readonly __typename?: 'WareCategory'

  readonly id: string

  readonly nameLabel: string

  readonly pictogram: Maybe<string>
}

export type FFoodMenuRecipeFragment = {
  readonly __typename?: 'Recipe'

  readonly id: string

  readonly image: Maybe<string>

  readonly descriptionLabel: Maybe<string>

  readonly price: FFoodMenuRecipePrice
} & FRecipeFragment

export type FFoodMenuRecipePrice = FPriceFragment

export type FOrderCreateFragment = {
  readonly __typename?: 'Order'

  readonly id: string

  readonly branch: FOrderCreateBranch
}

export type FOrderCreateBranch = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string
}

export type FPickupBranchFragment = {
  readonly __typename?: 'CompanyBranch'

  readonly id: string

  readonly name: string

  readonly gps: FPickupBranchGps

  readonly address: FPickupBranchAddress

  readonly pickupEnabled: boolean

  readonly defaultDeliveryTime: number

  readonly defaultPickupTime: number
} & FCompanyBranchStatusFragment

export type FPickupBranchGps = FGeoPointFragment

export type FPickupBranchAddress = FAddressFragment

export type FShopOpeningHoursFragment = {
  readonly __typename?: 'CompanyBranch'

  readonly defaultDeliveryTime: number

  readonly defaultPickupTime: number
} & FCompanyBranchStatusFragment

import * as Hooks from '@speedlo/graphql/dist/hooks'
import gql from 'graphql-tag'

// ====================================================
// Fragments
// ====================================================

export const FCompanyBranchFragmentDoc = gql`
  fragment FCompanyBranch on CompanyBranch {
    id
    code: wac
    brand {
      id
      name
      code: wac
    }
    company {
      id
    }
    address {
      id
      country {
        phonePrefix
      }
    }
    url
    phone
    supportedLanguages(userSpecific: true)
  }
`

export const FPromoCodeFragmentDoc = gql`
  fragment FPromoCode on PromoCode {
    id
    code
  }
`

export const FRecipeFragmentDoc = gql`
  fragment FRecipe on Recipe {
    id
    nameLabel
    sideDishCount: sideDishesWareCategoriesCount
  }
`

export const FCartItemFragmentDoc = gql`
  fragment FCartItem on OrderRecipe {
    id
    recipe {
      ...FRecipe
    }
  }

  ${FRecipeFragmentDoc}
`

export const FPriceFragmentDoc = gql`
  fragment FPrice on PriceConverted {
    value
    formattedValue
  }
`

export const FCartGoodsFragmentDoc = gql`
  fragment FCartGoods on Order {
    items {
      ...FCartItem
      automatic
      price: priceWithSideDishes {
        ...FPrice
      }
      sideDishes {
        ...FCartItem
      }
    }
    coverPrice {
      ...FPrice
    }
    transportFee {
      ...FPrice
    }
    discounts {
      code
      description
      price {
        ...FPrice
      }
    }
    vouchers {
      code
      description
      price {
        ...FPrice
      }
    }
    totalDiscount {
      ...FPrice
    }
    tip {
      ...FPrice
    }
  }

  ${FCartItemFragmentDoc}
  ${FPriceFragmentDoc}
`

export const FCartStaticFragmentDoc = gql`
  fragment FCartStatic on Order {
    minimalOrderPrice {
      ...FPrice
    }
    deliveryType {
      enum
    }
  }

  ${FPriceFragmentDoc}
`

export const FCartSummaryFragmentDoc = gql`
  fragment FCartSummary on Order {
    id
    countWares
    totalSum {
      ...FPrice
    }
  }

  ${FPriceFragmentDoc}
`

export const FRecipeDetailFragmentDoc = gql`
  fragment FRecipeDetail on Recipe {
    id
    nameLabel
    descriptionLabel
    categories: sideDishesWareCategories {
      id
      nameLabel
      minCount
      maxCount
      maxRecipesCount
      sideDishes: recipes {
        id
        nameLabel
        price {
          ...FPrice
        }
      }
    }
  }

  ${FPriceFragmentDoc}
`

export const FOrderFinishedFragmentDoc = gql`
  fragment FOrderFinished on Order {
    id
    items {
      ...FCartItem
      sideDishes {
        ...FCartItem
      }
    }
    totalSum {
      ...FPrice
    }
    orderPayments {
      paymentMethod {
        paymentType {
          enum
        }
      }
    }
  }

  ${FCartItemFragmentDoc}
  ${FPriceFragmentDoc}
`

export const FCartPresenceFragmentDoc = gql`
  fragment FCartPresence on Order {
    id
    presence: items {
      id
      recipe {
        id
      }
    }
  }
`

export const FFoodMenuCategoryFragmentDoc = gql`
  fragment FFoodMenuCategory on WareCategory {
    id
    nameLabel
    pictogram
  }
`

export const FFoodMenuRecipeFragmentDoc = gql`
  fragment FFoodMenuRecipe on Recipe {
    id
    image(width: 192, height: 192)
    descriptionLabel
    price {
      ...FPrice
    }
    ...FRecipe
  }

  ${FPriceFragmentDoc}
  ${FRecipeFragmentDoc}
`

export const FOrderCreateFragmentDoc = gql`
  fragment FOrderCreate on Order {
    id
    branch: companyBranch {
      id
    }
  }
`

export const FGeoPointFragmentDoc = gql`
  fragment FGeoPoint on GeoPoint {
    latitude
    longitude
  }
`

export const FJsonAddressFragmentDoc = gql`
  fragment FJsonAddress on JsonAddress {
    street
    houseNumber
    city
    state
    venue
  }
`

export const FAddressFragmentDoc = gql`
  fragment FAddress on Address {
    id
    location {
      ...FJsonAddress
    }
  }

  ${FJsonAddressFragmentDoc}
`

export const FCompanyBranchStatusFragmentDoc = gql`
  fragment FCompanyBranchStatus on CompanyBranch {
    id
    isOpen
    willOpenAt
    willCloseAt
    businessHours: businessHoursIntervals {
      openAt
      closeAt
      closed
      closedNote
    }
    currentInterval {
      closedNote
    }
    defaultDeliveryTimeEnabled
    defaultPickupTimeEnabled
  }
`

export const FPickupBranchFragmentDoc = gql`
  fragment FPickupBranch on CompanyBranch {
    id
    name
    gps {
      ...FGeoPoint
    }
    address {
      ...FAddress
    }
    pickupEnabled
    defaultDeliveryTime: currentDeliveryTime
    defaultPickupTime: currentPickupTime
    ...FCompanyBranchStatus
  }

  ${FGeoPointFragmentDoc}
  ${FAddressFragmentDoc}
  ${FCompanyBranchStatusFragmentDoc}
`

export const FShopOpeningHoursFragmentDoc = gql`
  fragment FShopOpeningHours on CompanyBranch {
    defaultDeliveryTime: currentDeliveryTime
    defaultPickupTime: currentPickupTime
    ...FCompanyBranchStatus
  }

  ${FCompanyBranchStatusFragmentDoc}
`

// ====================================================
// Hooks
// ====================================================

export const QBusinessLookupDocument = gql`
  query QBusinessLookup($url: String) {
    lookup: companyBranchLookUp(hostUrl: $url) {
      branch: companyBranch {
        ...FCompanyBranch
      }
      redirectUrl
      isBrand: isBrandUrl
      isMobileApp
      knownBasePath
      orderOrigin
      defaultLanguage: userDefaultLanguage
      analytics: analyticsCodes {
        code
        type: analyticsCodeType {
          enum
        }
      }
    }
  }

  ${FCompanyBranchFragmentDoc}
`

export function useQBusinessLookup(
  variables?: QBusinessLookupVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QBusinessLookupQuery,
    QBusinessLookupVariables
  >,
) {
  return Hooks.useQuery<QBusinessLookupQuery, QBusinessLookupVariables>(
    QBusinessLookupDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQBusinessLookup(
  options?: Hooks.LazyQueryHookOptions<
    QBusinessLookupQuery,
    QBusinessLookupVariables
  >,
) {
  return Hooks.useLazyQuery<QBusinessLookupQuery, QBusinessLookupVariables>(
    QBusinessLookupDocument,
    options,
  )
}

export const QDelayedOrderDocument = gql`
  query QDelayedOrder($branchId: ID!) {
    delayedOrder: companyBranch(id: $branchId) {
      id
      intervals: delayedDeliveryIntervals {
        start: openAt
        end: closeAt
      }
      enabled: delayedDeliveryEnabled
      ...FCompanyBranchStatus
    }
  }

  ${FCompanyBranchStatusFragmentDoc}
`

export function useQDelayedOrder(
  variables?: QDelayedOrderVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QDelayedOrderQuery,
    QDelayedOrderVariables
  >,
) {
  return Hooks.useQuery<QDelayedOrderQuery, QDelayedOrderVariables>(
    QDelayedOrderDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQDelayedOrder(
  options?: Hooks.LazyQueryHookOptions<
    QDelayedOrderQuery,
    QDelayedOrderVariables
  >,
) {
  return Hooks.useLazyQuery<QDelayedOrderQuery, QDelayedOrderVariables>(
    QDelayedOrderDocument,
    options,
  )
}

export const QThemeStyleDocument = gql`
  query QThemeStyle($brandId: ID, $branchId: ID) {
    theme: applicationSettings(brand: $brandId, companyBranch: $branchId) {
      styles {
        active
        main
        mainSecondary
        storeLogo
        storeLogoMobile
        backgroundCart
      }
    }
  }
`

export function useQThemeStyle(
  variables?: QThemeStyleVariables,
  baseOptions?: Hooks.QueryHookOptions<QThemeStyleQuery, QThemeStyleVariables>,
) {
  return Hooks.useQuery<QThemeStyleQuery, QThemeStyleVariables>(
    QThemeStyleDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQThemeStyle(
  options?: Hooks.LazyQueryHookOptions<QThemeStyleQuery, QThemeStyleVariables>,
) {
  return Hooks.useLazyQuery<QThemeStyleQuery, QThemeStyleVariables>(
    QThemeStyleDocument,
    options,
  )
}

export const MAddToCartDocument = gql`
  mutation MAddToCart(
    $orderId: ID!
    $recipeId: ID!
    $sideDishes: [ID!]
    $withGoods: Boolean!
    $withPresence: Boolean!
  ) {
    add: createOrderRecipe(
      orderRecipe: {
        order: $orderId
        recipe: $recipeId
        sideDishes: $sideDishes
      }
    ) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
      ...FCartPresence @include(if: $withPresence)
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
  ${FCartPresenceFragmentDoc}
`

export function useMAddToCart(
  baseOptions?: Hooks.MutationHookOptions<
    MAddToCartMutation,
    MAddToCartVariables
  >,
) {
  return Hooks.useMutation<MAddToCartMutation, MAddToCartVariables>(
    MAddToCartDocument,
    baseOptions,
  )
}

export const MChangeCartItemDocument = gql`
  mutation MChangeCartItem(
    $orderId: ID!
    $itemId: ID!
    $sideDishes: [ID!]
    $withGoods: Boolean!
  ) {
    change: updateOrderRecipe(
      id: $itemId
      orderRecipe: { order: $orderId, sideDishes: $sideDishes }
    ) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
`

export function useMChangeCartItem(
  baseOptions?: Hooks.MutationHookOptions<
    MChangeCartItemMutation,
    MChangeCartItemVariables
  >,
) {
  return Hooks.useMutation<MChangeCartItemMutation, MChangeCartItemVariables>(
    MChangeCartItemDocument,
    baseOptions,
  )
}

export const MRemoveCartItemDocument = gql`
  mutation MRemoveCartItem(
    $itemId: ID!
    $withGoods: Boolean!
    $withPresence: Boolean!
  ) {
    remove: destroyOrderRecipe(id: $itemId) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
      ...FCartPresence @include(if: $withPresence)
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
  ${FCartPresenceFragmentDoc}
`

export function useMRemoveCartItem(
  baseOptions?: Hooks.MutationHookOptions<
    MRemoveCartItemMutation,
    MRemoveCartItemVariables
  >,
) {
  return Hooks.useMutation<MRemoveCartItemMutation, MRemoveCartItemVariables>(
    MRemoveCartItemDocument,
    baseOptions,
  )
}

export const QCartDocument = gql`
  query QCart($orderId: ID!, $withGoods: Boolean!, $withPresence: Boolean!) {
    order(id: $orderId) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
      ...FCartPresence @include(if: $withPresence)
      ...FCartStatic
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
  ${FCartPresenceFragmentDoc}
  ${FCartStaticFragmentDoc}
`

export function useQCart(
  variables?: QCartVariables,
  baseOptions?: Hooks.QueryHookOptions<QCartQuery, QCartVariables>,
) {
  return Hooks.useQuery<QCartQuery, QCartVariables>(
    QCartDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQCart(
  options?: Hooks.LazyQueryHookOptions<QCartQuery, QCartVariables>,
) {
  return Hooks.useLazyQuery<QCartQuery, QCartVariables>(QCartDocument, options)
}

export const QCartItemDocument = gql`
  query QCartItem($itemId: ID!) {
    item: orderRecipe(id: $itemId) {
      id
      recipe {
        ...FRecipeDetail
      }
      sideDishes {
        id
        recipe {
          id
        }
      }
    }
  }

  ${FRecipeDetailFragmentDoc}
`

export function useQCartItem(
  variables?: QCartItemVariables,
  baseOptions?: Hooks.QueryHookOptions<QCartItemQuery, QCartItemVariables>,
) {
  return Hooks.useQuery<QCartItemQuery, QCartItemVariables>(
    QCartItemDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQCartItem(
  options?: Hooks.LazyQueryHookOptions<QCartItemQuery, QCartItemVariables>,
) {
  return Hooks.useLazyQuery<QCartItemQuery, QCartItemVariables>(
    QCartItemDocument,
    options,
  )
}

export const QCartRecipeDocument = gql`
  query QCartRecipe($recipeId: ID!, $branchId: ID) {
    recipe(id: $recipeId, companyBranch: $branchId) {
      ...FRecipeDetail
    }
  }

  ${FRecipeDetailFragmentDoc}
`

export function useQCartRecipe(
  variables?: QCartRecipeVariables,
  baseOptions?: Hooks.QueryHookOptions<QCartRecipeQuery, QCartRecipeVariables>,
) {
  return Hooks.useQuery<QCartRecipeQuery, QCartRecipeVariables>(
    QCartRecipeDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQCartRecipe(
  options?: Hooks.LazyQueryHookOptions<QCartRecipeQuery, QCartRecipeVariables>,
) {
  return Hooks.useLazyQuery<QCartRecipeQuery, QCartRecipeVariables>(
    QCartRecipeDocument,
    options,
  )
}

export const MCreateOrderRatingDocument = gql`
  mutation MCreateOrderRating(
    $orderId: ID!
    $ratings: [InputOrderRatingCreate!]!
  ) {
    createOrderRating(id: $orderId, ratings: $ratings) {
      id
    }
  }
`

export function useMCreateOrderRating(
  baseOptions?: Hooks.MutationHookOptions<
    MCreateOrderRatingMutation,
    MCreateOrderRatingVariables
  >,
) {
  return Hooks.useMutation<
    MCreateOrderRatingMutation,
    MCreateOrderRatingVariables
  >(MCreateOrderRatingDocument, baseOptions)
}

export const QOrderForFeedbackDocument = gql`
  query QOrderForFeedback($orderId: ID!) {
    order(id: $orderId) {
      id
      hasRating
      companyBranch {
        id
        phone
      }
    }
  }
`

export function useQOrderForFeedback(
  variables?: QOrderForFeedbackVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QOrderForFeedbackQuery,
    QOrderForFeedbackVariables
  >,
) {
  return Hooks.useQuery<QOrderForFeedbackQuery, QOrderForFeedbackVariables>(
    QOrderForFeedbackDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderForFeedback(
  options?: Hooks.LazyQueryHookOptions<
    QOrderForFeedbackQuery,
    QOrderForFeedbackVariables
  >,
) {
  return Hooks.useLazyQuery<QOrderForFeedbackQuery, QOrderForFeedbackVariables>(
    QOrderForFeedbackDocument,
    options,
  )
}

export const QOrderFinishDocument = gql`
  query QOrderFinish($orderId: ID!) {
    order(id: $orderId) {
      ...FOrderFinished
      companyBranch {
        id
        phone
        company {
          id
        }
        androidApp: mobileApplicationSetting(platform: ANDROID) {
          url
        }
        iosApp: mobileApplicationSetting(platform: IOS) {
          url
        }
      }
    }
  }

  ${FOrderFinishedFragmentDoc}
`

export function useQOrderFinish(
  variables?: QOrderFinishVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QOrderFinishQuery,
    QOrderFinishVariables
  >,
) {
  return Hooks.useQuery<QOrderFinishQuery, QOrderFinishVariables>(
    QOrderFinishDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderFinish(
  options?: Hooks.LazyQueryHookOptions<
    QOrderFinishQuery,
    QOrderFinishVariables
  >,
) {
  return Hooks.useLazyQuery<QOrderFinishQuery, QOrderFinishVariables>(
    QOrderFinishDocument,
    options,
  )
}

export const QOrderStateDocument = gql`
  query QOrderState($orderId: ID!) {
    order(id: $orderId) {
      id
      deliveryType {
        enum
      }
      orderStateCategory(target: FE_ADMIN)
      companyBranch {
        id
        allowedCategories(target: FE_ADMIN)
      }
      transferredIntoOrder: transferOrderTo {
        id
      }
    }
  }
`

export function useQOrderState(
  variables?: QOrderStateVariables,
  baseOptions?: Hooks.QueryHookOptions<QOrderStateQuery, QOrderStateVariables>,
) {
  return Hooks.useQuery<QOrderStateQuery, QOrderStateVariables>(
    QOrderStateDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderState(
  options?: Hooks.LazyQueryHookOptions<QOrderStateQuery, QOrderStateVariables>,
) {
  return Hooks.useLazyQuery<QOrderStateQuery, QOrderStateVariables>(
    QOrderStateDocument,
    options,
  )
}

export const SUpdateOrderDocument = gql`
  subscription SUpdateOrder($orderId: ID!, $companyBranches: [ID!]!) {
    updatedOrder(
      id: $orderId
      companyBranches: $companyBranches
      orderStateCategoryTarget: FE_ADMIN
    ) {
      order {
        id
        deliveryType {
          enum
        }
        orderStateCategory(target: FE_ADMIN)
        transferredIntoOrder: transferOrderTo {
          id
        }
      }
    }
  }
`

export function useSUpdateOrder(
  variables?: SUpdateOrderVariables,
  baseOptions?: Hooks.SubscriptionHookOptions<
    SUpdateOrderSubscription,
    SUpdateOrderVariables
  >,
) {
  return Hooks.useSubscription<SUpdateOrderSubscription, SUpdateOrderVariables>(
    SUpdateOrderDocument,
    variables,
    baseOptions,
  )
}

export const QFoodMenuDocument = gql`
  query QFoodMenu($branchId: ID!) {
    wareCategories(
      companyBranch: $branchId
      wareCategoryType: MAIN
      orderOriginCategory: DOVEZE
      enabled: true
      isAvailable: true
    ) {
      ...FFoodMenuCategory
      recipes(enabled: true, isAvailable: true) {
        ...FFoodMenuRecipe
      }
    }
  }

  ${FFoodMenuCategoryFragmentDoc}
  ${FFoodMenuRecipeFragmentDoc}
`

export function useQFoodMenu(
  variables?: QFoodMenuVariables,
  baseOptions?: Hooks.QueryHookOptions<QFoodMenuQuery, QFoodMenuVariables>,
) {
  return Hooks.useQuery<QFoodMenuQuery, QFoodMenuVariables>(
    QFoodMenuDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQFoodMenu(
  options?: Hooks.LazyQueryHookOptions<QFoodMenuQuery, QFoodMenuVariables>,
) {
  return Hooks.useLazyQuery<QFoodMenuQuery, QFoodMenuVariables>(
    QFoodMenuDocument,
    options,
  )
}

export const QFoodMenuCartPresenceDocument = gql`
  query QFoodMenuCartPresence($orderId: ID!) {
    order(id: $orderId) {
      id
      ...FCartPresence
    }
  }

  ${FCartPresenceFragmentDoc}
`

export function useQFoodMenuCartPresence(
  variables?: QFoodMenuCartPresenceVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QFoodMenuCartPresenceQuery,
    QFoodMenuCartPresenceVariables
  >,
) {
  return Hooks.useQuery<
    QFoodMenuCartPresenceQuery,
    QFoodMenuCartPresenceVariables
  >(QFoodMenuCartPresenceDocument, variables, baseOptions)
}

export function useLazyQFoodMenuCartPresence(
  options?: Hooks.LazyQueryHookOptions<
    QFoodMenuCartPresenceQuery,
    QFoodMenuCartPresenceVariables
  >,
) {
  return Hooks.useLazyQuery<
    QFoodMenuCartPresenceQuery,
    QFoodMenuCartPresenceVariables
  >(QFoodMenuCartPresenceDocument, options)
}

export const MDeliveryOrderCreateDocument = gql`
  mutation MDeliveryOrderCreate(
    $brandId: ID
    $branchId: ID
    $gps: InputGeoPointCreate!
    $orderOrigin: OrderOriginEnum!
    $address: String
  ) {
    order: createDeliveryOrder(
      order: {
        brand: $brandId
        companyBranch: $branchId
        gps: $gps
        orderOrigin: $orderOrigin
        address: $address
      }
    ) {
      ...FOrderCreate
    }
  }

  ${FOrderCreateFragmentDoc}
`

export function useMDeliveryOrderCreate(
  baseOptions?: Hooks.MutationHookOptions<
    MDeliveryOrderCreateMutation,
    MDeliveryOrderCreateVariables
  >,
) {
  return Hooks.useMutation<
    MDeliveryOrderCreateMutation,
    MDeliveryOrderCreateVariables
  >(MDeliveryOrderCreateDocument, baseOptions)
}

export const MPickupOrderCreateDocument = gql`
  mutation MPickupOrderCreate($branchId: ID!, $orderOrigin: OrderOriginEnum!) {
    order: createPickupOrder(
      order: { companyBranch: $branchId, orderOrigin: $orderOrigin }
    ) {
      ...FOrderCreate
    }
  }

  ${FOrderCreateFragmentDoc}
`

export function useMPickupOrderCreate(
  baseOptions?: Hooks.MutationHookOptions<
    MPickupOrderCreateMutation,
    MPickupOrderCreateVariables
  >,
) {
  return Hooks.useMutation<
    MPickupOrderCreateMutation,
    MPickupOrderCreateVariables
  >(MPickupOrderCreateDocument, baseOptions)
}

export const QInitialDeliveryLocationDocument = gql`
  query QInitialDeliveryLocation {
    location: resolveLocation {
      gpsCoordinates {
        latitude
        longitude
      }
    }
  }
`

export function useQInitialDeliveryLocation(
  variables?: QInitialDeliveryLocationVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QInitialDeliveryLocationQuery,
    QInitialDeliveryLocationVariables
  >,
) {
  return Hooks.useQuery<
    QInitialDeliveryLocationQuery,
    QInitialDeliveryLocationVariables
  >(QInitialDeliveryLocationDocument, variables, baseOptions)
}

export function useLazyQInitialDeliveryLocation(
  options?: Hooks.LazyQueryHookOptions<
    QInitialDeliveryLocationQuery,
    QInitialDeliveryLocationVariables
  >,
) {
  return Hooks.useLazyQuery<
    QInitialDeliveryLocationQuery,
    QInitialDeliveryLocationVariables
  >(QInitialDeliveryLocationDocument, options)
}

export const QInitialLocationDocument = gql`
  query QInitialLocation {
    initialLocation: resolveLocation {
      gpsCoordinates {
        latitude
        longitude
      }
    }
  }
`

export function useQInitialLocation(
  variables?: QInitialLocationVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QInitialLocationQuery,
    QInitialLocationVariables
  >,
) {
  return Hooks.useQuery<QInitialLocationQuery, QInitialLocationVariables>(
    QInitialLocationDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQInitialLocation(
  options?: Hooks.LazyQueryHookOptions<
    QInitialLocationQuery,
    QInitialLocationVariables
  >,
) {
  return Hooks.useLazyQuery<QInitialLocationQuery, QInitialLocationVariables>(
    QInitialLocationDocument,
    options,
  )
}

export const QPickupMapDocument = gql`
  query QPickupMap($branchSpecific: Boolean!, $brandId: ID, $branchId: ID!) {
    brand: companyBranches(brand: $brandId) @skip(if: $branchSpecific) {
      ...FPickupBranch
    }
    branch: companyBranch(id: $branchId) @include(if: $branchSpecific) {
      ...FPickupBranch
    }
  }

  ${FPickupBranchFragmentDoc}
`

export function useQPickupMap(
  variables?: QPickupMapVariables,
  baseOptions?: Hooks.QueryHookOptions<QPickupMapQuery, QPickupMapVariables>,
) {
  return Hooks.useQuery<QPickupMapQuery, QPickupMapVariables>(
    QPickupMapDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQPickupMap(
  options?: Hooks.LazyQueryHookOptions<QPickupMapQuery, QPickupMapVariables>,
) {
  return Hooks.useLazyQuery<QPickupMapQuery, QPickupMapVariables>(
    QPickupMapDocument,
    options,
  )
}

export const QResolveLocationDocument = gql`
  query QResolveLocation(
    $brandId: ID
    $branchId: ID
    $address: String
    $latitude: Float
    $longitude: Float
  ) {
    resolveLocation(
      brand: $brandId
      companyBranch: $branchId
      address: $address
      latitude: $latitude
      longitude: $longitude
    ) {
      finalAddress {
        ...FJsonAddress
      }
      isResolved
      isValidAddress
      inDeliveryLocation
      gpsCoordinates {
        latitude
        longitude
      }
      companyBranch {
        id
        isOpen
      }
      zone {
        id
        deliveryTimeDeviation
      }
    }
  }

  ${FJsonAddressFragmentDoc}
`

export function useQResolveLocation(
  variables?: QResolveLocationVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QResolveLocationQuery,
    QResolveLocationVariables
  >,
) {
  return Hooks.useQuery<QResolveLocationQuery, QResolveLocationVariables>(
    QResolveLocationDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQResolveLocation(
  options?: Hooks.LazyQueryHookOptions<
    QResolveLocationQuery,
    QResolveLocationVariables
  >,
) {
  return Hooks.useLazyQuery<QResolveLocationQuery, QResolveLocationVariables>(
    QResolveLocationDocument,
    options,
  )
}

export const QMobileShopIntroDocument = gql`
  query QMobileShopIntro($brandId: ID, $branchId: ID) {
    intro: applicationSettings(brand: $brandId, companyBranch: $branchId) {
      headerText
      homepageUrl
    }
  }
`

export function useQMobileShopIntro(
  variables?: QMobileShopIntroVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QMobileShopIntroQuery,
    QMobileShopIntroVariables
  >,
) {
  return Hooks.useQuery<QMobileShopIntroQuery, QMobileShopIntroVariables>(
    QMobileShopIntroDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQMobileShopIntro(
  options?: Hooks.LazyQueryHookOptions<
    QMobileShopIntroQuery,
    QMobileShopIntroVariables
  >,
) {
  return Hooks.useLazyQuery<QMobileShopIntroQuery, QMobileShopIntroVariables>(
    QMobileShopIntroDocument,
    options,
  )
}

export const QMobileShopStatusDocument = gql`
  query QMobileShopStatus($branchId: ID!) {
    branch: companyBranch(id: $branchId) {
      id
      ...FCompanyBranchStatus
    }
  }

  ${FCompanyBranchStatusFragmentDoc}
`

export function useQMobileShopStatus(
  variables?: QMobileShopStatusVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QMobileShopStatusQuery,
    QMobileShopStatusVariables
  >,
) {
  return Hooks.useQuery<QMobileShopStatusQuery, QMobileShopStatusVariables>(
    QMobileShopStatusDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQMobileShopStatus(
  options?: Hooks.LazyQueryHookOptions<
    QMobileShopStatusQuery,
    QMobileShopStatusVariables
  >,
) {
  return Hooks.useLazyQuery<QMobileShopStatusQuery, QMobileShopStatusVariables>(
    QMobileShopStatusDocument,
    options,
  )
}

export const QSmartBannerDocument = gql`
  query QSmartBanner($branchId: ID!, $platform: MobilePlatformEnum!) {
    app: mobileApplicationSettings(
      companyBranch: $branchId
      platform: $platform
    ) {
      title
      url
      icon
    }
  }
`

export function useQSmartBanner(
  variables?: QSmartBannerVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QSmartBannerQuery,
    QSmartBannerVariables
  >,
) {
  return Hooks.useQuery<QSmartBannerQuery, QSmartBannerVariables>(
    QSmartBannerDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQSmartBanner(
  options?: Hooks.LazyQueryHookOptions<
    QSmartBannerQuery,
    QSmartBannerVariables
  >,
) {
  return Hooks.useLazyQuery<QSmartBannerQuery, QSmartBannerVariables>(
    QSmartBannerDocument,
    options,
  )
}

export const MOrderDeliveryTimeDocument = gql`
  mutation MOrderDeliveryTime(
    $orderId: ID!
    $deliverAt: DateTime
    $withGoods: Boolean!
  ) {
    updateOrder(id: $orderId, order: { deliverAt: $deliverAt }) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
`

export function useMOrderDeliveryTime(
  baseOptions?: Hooks.MutationHookOptions<
    MOrderDeliveryTimeMutation,
    MOrderDeliveryTimeVariables
  >,
) {
  return Hooks.useMutation<
    MOrderDeliveryTimeMutation,
    MOrderDeliveryTimeVariables
  >(MOrderDeliveryTimeDocument, baseOptions)
}

export const MOrderPromoAddDocument = gql`
  mutation MOrderPromoAdd($orderId: ID!, $code: String!, $withGoods: Boolean!) {
    addOrderPromoCode(order: $orderId, promoCode: $code) {
      userErrors: errors
      order {
        id
        promoCodes {
          ...FPromoCode
        }
        ...FCartSummary
        ...FCartGoods @include(if: $withGoods)
      }
    }
  }

  ${FPromoCodeFragmentDoc}
  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
`

export function useMOrderPromoAdd(
  baseOptions?: Hooks.MutationHookOptions<
    MOrderPromoAddMutation,
    MOrderPromoAddVariables
  >,
) {
  return Hooks.useMutation<MOrderPromoAddMutation, MOrderPromoAddVariables>(
    MOrderPromoAddDocument,
    baseOptions,
  )
}

export const MOrderPromoRemoveDocument = gql`
  mutation MOrderPromoRemove(
    $orderId: ID!
    $codeId: ID!
    $withGoods: Boolean!
  ) {
    removeOrderPromoCode(order: $orderId, promoCode: $codeId) {
      id
      promoCodes {
        ...FPromoCode
      }
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
    }
  }

  ${FPromoCodeFragmentDoc}
  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
`

export function useMOrderPromoRemove(
  baseOptions?: Hooks.MutationHookOptions<
    MOrderPromoRemoveMutation,
    MOrderPromoRemoveVariables
  >,
) {
  return Hooks.useMutation<
    MOrderPromoRemoveMutation,
    MOrderPromoRemoveVariables
  >(MOrderPromoRemoveDocument, baseOptions)
}

export const MOrderTipUpdateDocument = gql`
  mutation MOrderTipUpdate($orderId: ID!, $tips: Float!, $withGoods: Boolean!) {
    updateOrder(id: $orderId, order: { tips: $tips }) {
      ...FCartSummary
      ...FCartGoods @include(if: $withGoods)
    }
  }

  ${FCartSummaryFragmentDoc}
  ${FCartGoodsFragmentDoc}
`

export function useMOrderTipUpdate(
  baseOptions?: Hooks.MutationHookOptions<
    MOrderTipUpdateMutation,
    MOrderTipUpdateVariables
  >,
) {
  return Hooks.useMutation<MOrderTipUpdateMutation, MOrderTipUpdateVariables>(
    MOrderTipUpdateDocument,
    baseOptions,
  )
}

export const MSubmitOrderDocument = gql`
  mutation MSubmitOrder(
    $orderId: ID!
    $customer: InputCustomerCreate
    $note: String
    $tips: Float
    $paymentGate: PaymentGateEnum!
    $deliverAt: DateTime
    $agreements: [ID!]
    $returnUrl: String
  ) {
    submitOrder: finishOrder(
      id: $orderId
      order: {
        confirmedAgreements: $agreements
        customer: $customer
        paymentGate: $paymentGate
        note: $note
        tips: $tips
        deliverAt: $deliverAt
        returnUrl: $returnUrl
      }
    ) {
      userErrors
      gopayUrl
      payuUrl
      csobUrl
    }
  }
`

export function useMSubmitOrder(
  baseOptions?: Hooks.MutationHookOptions<
    MSubmitOrderMutation,
    MSubmitOrderVariables
  >,
) {
  return Hooks.useMutation<MSubmitOrderMutation, MSubmitOrderVariables>(
    MSubmitOrderDocument,
    baseOptions,
  )
}

export const QOrderFormDocument = gql`
  query QOrderForm($orderId: ID!) {
    order(id: $orderId) {
      id
      countWares
      orderStateCategory
    }
  }
`

export function useQOrderForm(
  variables?: QOrderFormVariables,
  baseOptions?: Hooks.QueryHookOptions<QOrderFormQuery, QOrderFormVariables>,
) {
  return Hooks.useQuery<QOrderFormQuery, QOrderFormVariables>(
    QOrderFormDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderForm(
  options?: Hooks.LazyQueryHookOptions<QOrderFormQuery, QOrderFormVariables>,
) {
  return Hooks.useLazyQuery<QOrderFormQuery, QOrderFormVariables>(
    QOrderFormDocument,
    options,
  )
}

export const QOrderFormAgreementsDocument = gql`
  query QOrderFormAgreements($input: InputVamStatus!) {
    agreements: vamStatus(input: $input) {
      id
      name
      description
      isConfirmed
      isMandatory
    }
  }
`

export function useQOrderFormAgreements(
  variables?: QOrderFormAgreementsVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QOrderFormAgreementsQuery,
    QOrderFormAgreementsVariables
  >,
) {
  return Hooks.useQuery<
    QOrderFormAgreementsQuery,
    QOrderFormAgreementsVariables
  >(QOrderFormAgreementsDocument, variables, baseOptions)
}

export function useLazyQOrderFormAgreements(
  options?: Hooks.LazyQueryHookOptions<
    QOrderFormAgreementsQuery,
    QOrderFormAgreementsVariables
  >,
) {
  return Hooks.useLazyQuery<
    QOrderFormAgreementsQuery,
    QOrderFormAgreementsVariables
  >(QOrderFormAgreementsDocument, options)
}

export const QOrderPaymentStatusDocument = gql`
  query QOrderPaymentStatus($orderId: ID!) {
    status: orderOnlinePaymentStatus(id: $orderId) {
      isSuccess
      userErrors
    }
  }
`

export function useQOrderPaymentStatus(
  variables?: QOrderPaymentStatusVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QOrderPaymentStatusQuery,
    QOrderPaymentStatusVariables
  >,
) {
  return Hooks.useQuery<QOrderPaymentStatusQuery, QOrderPaymentStatusVariables>(
    QOrderPaymentStatusDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderPaymentStatus(
  options?: Hooks.LazyQueryHookOptions<
    QOrderPaymentStatusQuery,
    QOrderPaymentStatusVariables
  >,
) {
  return Hooks.useLazyQuery<
    QOrderPaymentStatusQuery,
    QOrderPaymentStatusVariables
  >(QOrderPaymentStatusDocument, options)
}

export const QOrderPromoCodesDocument = gql`
  query QOrderPromoCodes($orderId: ID!) {
    order(id: $orderId) {
      id
      promoCodes {
        ...FPromoCode
      }
    }
  }

  ${FPromoCodeFragmentDoc}
`

export function useQOrderPromoCodes(
  variables?: QOrderPromoCodesVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QOrderPromoCodesQuery,
    QOrderPromoCodesVariables
  >,
) {
  return Hooks.useQuery<QOrderPromoCodesQuery, QOrderPromoCodesVariables>(
    QOrderPromoCodesDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQOrderPromoCodes(
  options?: Hooks.LazyQueryHookOptions<
    QOrderPromoCodesQuery,
    QOrderPromoCodesVariables
  >,
) {
  return Hooks.useLazyQuery<QOrderPromoCodesQuery, QOrderPromoCodesVariables>(
    QOrderPromoCodesDocument,
    options,
  )
}

export const QPaymentMethodsDocument = gql`
  query QPaymentMethods(
    $branchId: ID!
    $orderOrigin: OrderOriginEnum!
    $deliveryType: DeliveryTypeEnum!
  ) {
    companyBranch(id: $branchId) {
      id
      paymentMethods(
        orderOrigin: $orderOrigin
        warePriceType: DELIVERY
        deliveryType: $deliveryType
      ) {
        paymentMethod {
          id
          description
          paymentGate {
            enum
            code
          }
        }
      }
    }
  }
`

export function useQPaymentMethods(
  variables?: QPaymentMethodsVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QPaymentMethodsQuery,
    QPaymentMethodsVariables
  >,
) {
  return Hooks.useQuery<QPaymentMethodsQuery, QPaymentMethodsVariables>(
    QPaymentMethodsDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQPaymentMethods(
  options?: Hooks.LazyQueryHookOptions<
    QPaymentMethodsQuery,
    QPaymentMethodsVariables
  >,
) {
  return Hooks.useLazyQuery<QPaymentMethodsQuery, QPaymentMethodsVariables>(
    QPaymentMethodsDocument,
    options,
  )
}

export const QShopHeaderBranchDocument = gql`
  query QShopHeaderBranch($branchId: ID!) {
    branch: companyBranch(id: $branchId) {
      id
      address {
        ...FAddress
      }
      ...FShopOpeningHours
      ...FCompanyBranchStatus
    }
  }

  ${FAddressFragmentDoc}
  ${FShopOpeningHoursFragmentDoc}
  ${FCompanyBranchStatusFragmentDoc}
`

export function useQShopHeaderBranch(
  variables?: QShopHeaderBranchVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QShopHeaderBranchQuery,
    QShopHeaderBranchVariables
  >,
) {
  return Hooks.useQuery<QShopHeaderBranchQuery, QShopHeaderBranchVariables>(
    QShopHeaderBranchDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQShopHeaderBranch(
  options?: Hooks.LazyQueryHookOptions<
    QShopHeaderBranchQuery,
    QShopHeaderBranchVariables
  >,
) {
  return Hooks.useLazyQuery<QShopHeaderBranchQuery, QShopHeaderBranchVariables>(
    QShopHeaderBranchDocument,
    options,
  )
}

export const QShopHeaderIntroDocument = gql`
  query QShopHeaderIntro($brandId: ID, $branchId: ID) {
    intro: applicationSettings(brand: $brandId, companyBranch: $branchId) {
      headerText
      homepageUrl
    }
  }
`

export function useQShopHeaderIntro(
  variables?: QShopHeaderIntroVariables,
  baseOptions?: Hooks.QueryHookOptions<
    QShopHeaderIntroQuery,
    QShopHeaderIntroVariables
  >,
) {
  return Hooks.useQuery<QShopHeaderIntroQuery, QShopHeaderIntroVariables>(
    QShopHeaderIntroDocument,
    variables,
    baseOptions,
  )
}

export function useLazyQShopHeaderIntro(
  options?: Hooks.LazyQueryHookOptions<
    QShopHeaderIntroQuery,
    QShopHeaderIntroVariables
  >,
) {
  return Hooks.useLazyQuery<QShopHeaderIntroQuery, QShopHeaderIntroVariables>(
    QShopHeaderIntroDocument,
    options,
  )
}

// Generated in 2024-11-13T16:19:17+00:00
